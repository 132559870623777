export const CONTACT_REQUEST="CONTACT_REQUEST";
export const CONTACT_SUCCESS="CONTACT_SUCCESS";
export const CONTACT_RESET="CONTACT_RESET";
export const CONTACT_FAIL="CONTACT_FAIL";

export const ALL_CONTACT_REQUEST="ALL_CONTACT_REQUEST";
export const ALL_CONTACT_SUCCESS="ALL_CONTACT_SUCCESS";
export const ALL_CONTACT_FAIL="ALL_CONTACT_FAIL";

export const DELETE_CONTACT_REQUEST="DELETE_CONTACT_REQUEST";
export const DELETE_CONTACT_SUCCESS="DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_RESET="DELETE_CONTACT_RESET";
export const DELETE_CONTACT_FAIL="DELETE_CONTACT_FAIL";

export const CLEAR_ERRORS="CLEAR_ERRORS";   
