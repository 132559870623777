import React from "react";
import { useAlert } from "react-alert";
import { logout } from "../actions/userAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../Components/Navbar/Rectangle.png";

function Sidebar() {
  const location = useLocation();
  const currentLocation = location.pathname;

  const activeMenu =
    "relative flex items-center space-x-4 rounded-xl bg-gradient-to-r from-[#224185] to-[#2f5bb5] px-4 py-3 text-white";
  const menu =
    "group flex items-center space-x-4 rounded-md px-4 py-3   text-gray-400";

  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function logoutUser() {
    dispatch(logout());
    alert.success("Logout Successfully");
    navigate("/home");
  }

  return (
    <div className="bg-[#071738] ">
      <aside className=" ml-[-100%] flex h-screen flex-col justify-between border-r bg-[#010d23] px-6 pb-3 transition duration-300  lg:ml-0    border-gray-700">
        <div>
          <div className="mt-8 text-center">
            <img
              src={logo}
              alt="logo"
              className=" md:py-0 w-52 md:w-52 mx-auto"
            />
            <h5 className="mt-4 hidden text-xl font-semibold  lg:block  text-white">
              Anthony's Auto
            </h5>
            <span className="hidden text-gray-400 lg:block">Admin</span>
          </div>

          <ul className="mt-8 space-y-2 tracking-wide">
            <li>
              <Link
                to={"/dashboard"}
                className={currentLocation === "/dashboard" ? activeMenu : menu}
              >
                <svg className="-ml-1 h-6 w-6" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8ZM6 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-1Z"
                    className="fill-current text-[#2f5bb5]"
                  ></path>
                  <path
                    d="M13 8a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2V8Z"
                    className="fill-current text-[#2f5bb5] group-hover:text-[#2f5bb5]"
                  ></path>
                  <path
                    d="M13 15a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-1Z"
                    className="fill-current group-hover:text-[#2f5bb5]"
                  ></path>
                </svg>
                <span className="-mr-1 font-medium  text-white">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to={"/dashboard/inventory"}
                className={
                  currentLocation === "/dashboard/inventory" || location.pathname.startsWith(`/dashboard/inventory/`) ? activeMenu : menu
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    className="fill-current text-gray-300 group-hover:text-[#3e6dcb]"
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1H8a3 3 0 00-3 3v1.5a1.5 1.5 0 01-3 0V6z"
                    clipRule="evenodd"
                  />
                  <path
                    className="fill-current text-gray-600  group-hover:text-[#2f5bb5]"
                    d="M6 12a2 2 0 012-2h8a2 2 0 012 2v2a2 2 0 01-2 2H2h2a2 2 0 002-2v-2z"
                  />
                </svg>
                <span className=" group-hover:text-gray-50">
                  Inventory
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={"/dashboard/add-car"}
                className={
                  currentLocation === "/dashboard/add-car" ? activeMenu : menu
                }
              >
                <i className="ri-add-box-fill text-lg text-gray-500  group-hover:text-[#2f5bb5]"></i>
                <span className=" group-hover:text-gray-50">
                  Add Car
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={"/dashboard/enquiry"}
                className={
                  currentLocation === "/dashboard/enquiry" ? activeMenu : menu
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    className="fill-current text-gray-600  group-hover:text-[#2f5bb5]"
                    fillRule="evenodd"
                    d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                    clipRule="evenodd"
                  />
                  <path
                    className="fill-current text-gray-300 group-hover:text-[#4785ff]"
                    d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"
                  />
                </svg>
                <span className=" group-hover:text-gray-50">
                  Enquiry
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={"/dashboard/contact-form"}
                className={
                  currentLocation === "/dashboard/contact-form"
                    ? activeMenu
                    : menu
                }
              >
                <i className="ri-contacts-fill fill-current text-lg text-gray-600  group-hover:text-[#2f5bb5]"></i>
                <span className=" group-hover:text-gray-50">
                  Contact Form
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={"/dashboard/all-careers"}
                className={
                  currentLocation === "/dashboard/all-careers"
                    ? activeMenu
                    : menu
                }
              >
                <i className="ri-empathize-fill fill-current text-lg text-gray-600 group-hover:text-[#2f5bb5]"></i>
                <span className=" group-hover:text-gray-50">
                  Careers
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={"/dashboard/contact-developer"}
                className={
                  currentLocation === "/dashboard/contact-developer"
                    ? activeMenu
                    : menu
                }
              >
                <i className="ri-question-fill fill-current text-lg text-gray-600  group-hover:text-[#2f5bb5]"></i>
                <span className=" group-hover:text-white">
                  Get help
                </span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="-mx-6 flex items-center justify-between border-t px-6 pt-4 border-gray-700">
          <button
            className="group flex items-center space-x-4 rounded-md px-4 py-3  text-gray-300"
            onClick={logoutUser}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 hover:text-red-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            <span className=" text-gray-400 group-hover:text-white">
              Logout
            </span>
          </button>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
