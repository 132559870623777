// import React from 'react'
import React, { Fragment } from 'react'
import { Routes, Route } from "react-router-dom";
import Home from '../Pages/Home';
import Inventory from '../Pages/Inventory';
import Error from '../Pages/Error';
import AboutUs from '../Pages/AboutUs';
import ContactUs from '../Pages/ContactUs';
import Careers from '../Pages/Careers';
import CarDetail from '../Pages/CarDetail';
import Dashboard from '../Admin/Dashboard';
import Login from '../Admin/Login';
import Loader from '../Components/Loader/Loader';
import PriceRangeFilter from '../Components/PriceRange/PriceRangeFilter'

import ProtectedRoute from '../Components/ProtectedRoute';
import AdminInventory from '../Admin/AdminInventory';
import NewCar from '../Admin/NewCar';
import UpdateCar from '../Admin/UpdateCar';
import AllEnquiry from '../Admin/AllEnquiry';
import AllContact from '../Admin/AllContact';
import Developer from '../Admin/Developer';
import Cta from '../Components/CTA/Cta';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import DrivingDirections from '../Pages/DrivingDirections';
import AllCareer from '../Admin/AllCareer';


const Routers = () => {
  return (
<Fragment>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/car/:id" element={<CarDetail />} />
          <Route path="/inventory/:keyword" element={<Inventory />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/driving-directions" element={<DrivingDirections/>} />
          <Route path="*" element={<Error />} />

          {/* Test Routes */}
          <Route path="loader" element={<Loader />} />
          <Route path="test" element={<Cta />} />
          <Route path="test2" element={<PriceRangeFilter />} />
          {/* <Route path="/carlist" element={<CarTable />} /> */}


          {/* Admin Routes */}
          <Route path="/dashboard" element={<ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>} />
          <Route path="/dashboard/add-car" element={<ProtectedRoute>
            <NewCar />
          </ProtectedRoute>} />
          <Route path="/dashboard/inventory/:id" element={<ProtectedRoute>
            <UpdateCar />
          </ProtectedRoute>} />
          <Route path="/dashboard/enquiry" element={<ProtectedRoute>
            <AllEnquiry />
          </ProtectedRoute>} />
          <Route path="/dashboard/all-careers" element={<ProtectedRoute>
            <AllCareer />
          </ProtectedRoute>} />
          <Route path="/dashboard/contact-form" element={<ProtectedRoute>
            <AllContact />
          </ProtectedRoute>} />
          <Route path="/dashboard/contact-developer" element={<ProtectedRoute>
            <Developer />
          </ProtectedRoute>} />
          <Route path="/public/pdfs/:id" element={<ProtectedRoute>
            <Developer />
          </ProtectedRoute>} />
          <Route path="/admin-login" element={<Login />} />
          <Route path="/dashboard/inventory" element={<ProtectedRoute><AdminInventory /></ProtectedRoute>} />
          
        </Routes>
        </Fragment>
  )
}

export default Routers