import {
    ENQUIRY_FAIL,
    ENQUIRY_REQUEST,
    ENQUIRY_RESET,
    ENQUIRY_SUCCESS,
    DELETE_ENQUIRY_FAIL,
    DELETE_ENQUIRY_REQUEST,
    DELETE_ENQUIRY_RESET,
    DELETE_ENQUIRY_SUCCESS,
    ALL_ENQUIRY_FAIL,
    ALL_ENQUIRY_REQUEST,
    ALL_ENQUIRY_SUCCESS,
    CLEAR_ERRORS,
} from "../constants/enquiryConstants";



export const newEnquiryReducer = (state = { enquiry:{} }, action) => {
    
    switch (action.type) {
      case  ENQUIRY_REQUEST:
        return {
          loading: true,
          ...state,
        };
      case ENQUIRY_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          enquiry: action.payload.enquiry,
        };
      case ENQUIRY_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case ENQUIRY_RESET:
        return {
          ...state,
          success:false,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };



  export const allEnquiriesReducer = (state = {  enquiries:[] }, action) => {

    switch (action.type) {
        case ALL_ENQUIRY_REQUEST:
            case DELETE_ENQUIRY_REQUEST:
            return {
                ...state,
                loading:true,
            };
        case ALL_ENQUIRY_SUCCESS:
            return {
                ...state,
                loading:false,
                enquiries: action.payload,
            };
            case DELETE_ENQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
          case ALL_ENQUIRY_FAIL:
            case DELETE_ENQUIRY_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            };
            case DELETE_ENQUIRY_RESET:
      return {
        ...state,
        isDeleted:false,
      };
        case CLEAR_ERRORS:
            return {
                ...state,
                error:null,
            };
        
        default:
         return state;
    }
}