export const CAREER_REQUEST="CAREER_REQUEST";
export const CAREER_SUCCESS="CAREER_SUCCESS";
export const CAREER_RESET="CAREER_RESET";
export const CAREER_FAIL="CAREER_FAIL";

export const ALL_CAREER_REQUEST="ALL_CAREER_REQUEST";
export const ALL_CAREER_SUCCESS="ALL_CAREER_SUCCESS";
export const ALL_CAREER_FAIL="ALL_CAREER_FAIL";

export const DELETE_CAREER_REQUEST="DELETE_CAREER_REQUEST";
export const DELETE_CAREER_SUCCESS="DELETE_CAREER_SUCCESS";
export const DELETE_CAREER_RESET="DELETE_CAREER_RESET";
export const DELETE_CAREER_FAIL="DELETE_CAREER_FAIL";

export const CLEAR_ERRORS="CLEAR_ERRORS";   
