import React from 'react'
import Towing from "./Towing-bro-01.svg"
import twentyFour from "./24s.svg"

const CarTow = () => {
    return (
       
        <div class="bg--50  py-16 lg:py-20">
        <div class="container m-auto space-y-8 px-6 md:px-12 lg:px-20">
          <div class="items-center justify-center gap-16 text-center md:flex md:text-left">
            <div class="order-last mb-6 space-y-6 md:mb-0 md:w-7/12 lg:w-6/12">
               
              <div class="flex flex-wrap justify-center items-end gap-2 md:justify-start lg:gap-2">
              <img src={twentyFour} alt="24x7" className='lg:w-32 w-28'/>
              <h1 class="text-4xl font-bold text-[#224185]  pb-2  italic">Car Towing </h1>
              </div>

              <p class="text-lg font-medium text-gray-600 ">
              Your Roadside Rescue, Just a Call Away! Anytime, Anywhere! Call Us 24/7 for Fast and Reliable Towing Assistance.
              </p>
              <div class="flex flex-wrap pt-8 justify-center  gap-1 text-xl  lg:text-2xl font-bold items-end text-[#224185] md:justify-start lg:gap-1">
              <i class="ri-phone-fill "></i>Call Us At: 
              </div>
              <span className='text-4xl lg:text-5xl text-[#F27920] font-bold'>203-903-1544</span>
            </div>
            <img
              src={Towing}
              width="832"
              height="608"
              class="m-auto lg:w-5/12 md:w-6/12"
              loading="lazy"
              alt="mobility_illustration"
            />
          </div>
        </div>
      </div>
                                          
  )
}

export default CarTow