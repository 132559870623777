export const LOGIN_REQUEST="LOGIN_REQUEST";
export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGIN_FAIL="LOGIN_FAIL";


export const LOAD_USER_REQUEST="LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS="LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL="LOAD_USER_FAIL";



export const LOGOUT_SUCCESS="LOGOUT_SUCCESS";
export const LOGOUT_FAIL="LOGOUT";

export const CLEAR_ERRORS="CLEAR_ERRORS";   
