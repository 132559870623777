import axios from "axios";

import {
    ALL_CAR_FAIL,
    ALL_CAR_REQUEST,
    ALL_CAR_SUCCESS,
    ADMIN_CAR_FAIL,
    ADMIN_CAR_REQUEST,
    ADMIN_CAR_SUCCESS,
    NEW_CAR_FAIL,
    NEW_CAR_REQUEST,
    NEW_CAR_SUCCESS,
    UPDATE_CAR_FAIL,
    UPDATE_CAR_REQUEST,
    UPDATE_CAR_SUCCESS,
    DELETE_CAR_FAIL,
    DELETE_CAR_REQUEST,
    DELETE_CAR_SUCCESS,
    CAR_DETAILS_REQUEST,
    CAR_DETAILS_FAIL,
    CAR_DETAILS_SUCCESS,
    CLEAR_ERRORS,
} from "../constants/carConstants";

// Get Car in Client
export const getCar = (keyword="",currentPage,price=[10000,800000],bodyType,all)=> async (dispatch)=>{
try {
    
    dispatch({
        type: ALL_CAR_REQUEST
    });

    let link = `/api/v1/cars?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}`;


    if(bodyType){
      link = `/api/v1/cars?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&bodyType=${bodyType}`;
    }

    const {data} = await axios.get(link);

    dispatch({
        type: ALL_CAR_SUCCESS,
        payload: data,
    });
} catch (error) {
    dispatch({
        type: ALL_CAR_FAIL,
        payload: error.response.data.message,
    });
}

};


// Get ALL Cars in ADMIN
export const getAdminCar = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_CAR_REQUEST });

    const { data } = await axios.get("/api/v1/admin/cars");

    dispatch({
      type: ADMIN_CAR_SUCCESS,
      payload: data.cars,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};


//Add Car --- Admin
export const createCar = (carData) => async (dispatch) => {
  try {
    dispatch({ type:NEW_CAR_REQUEST });

    const config = {
      headers: {"Content-Type": "application/json"},
    };
    const { data } = await axios.post(`/api/v1/admin/cars/new`,carData,config);

    dispatch({
      type: NEW_CAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};


//Update Car --- Admin
export const updateCar = (id,carData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CAR_REQUEST });

    const config = {
      headers: {"Content-Type": "application/json"},
    };
    const { data } = await axios.put(`/api/v1/admin/car/${id}`,carData,config);

    dispatch({
      type: UPDATE_CAR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};



//Delete Car --- Admin
export const deleteCar = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CAR_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/car/${id}`);

    dispatch({
      type: DELETE_CAR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};




//CarDetails page
export const getCarDetails = (id) => async (dispatch) => {
    try {
      dispatch({ type:CAR_DETAILS_REQUEST });
  
      const { data } = await axios.get(`/api/v1/car/${id}`);
  
      dispatch({
        type: CAR_DETAILS_SUCCESS,
        payload: data.car,
      });
    } catch (error) {
      dispatch({
        type: CAR_DETAILS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

//Clearing Errors
export const clearErrors = ()=> async (dispatch)=>{
    dispatch({ type: CLEAR_ERRORS });
};