import axios from "axios";

import {
    ENQUIRY_FAIL,
    ENQUIRY_REQUEST,
    ENQUIRY_SUCCESS,
    ALL_ENQUIRY_FAIL,
    ALL_ENQUIRY_REQUEST,
    ALL_ENQUIRY_SUCCESS,
    DELETE_ENQUIRY_FAIL,
    DELETE_ENQUIRY_REQUEST,
    DELETE_ENQUIRY_SUCCESS,
    CLEAR_ERRORS
} from "../constants/enquiryConstants";


//Create enquiry
export const newEnquiry = (enquiryData) => async (dispatch) => {
    try {
      dispatch({ type:ENQUIRY_REQUEST });
  
      const config = {
        headers: {"Content-Type": "application/json"},
      };
      const { data } = await axios.post(`/api/v1/enquiry/new`,enquiryData,config);
  
      dispatch({
        type: ENQUIRY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENQUIRY_FAIL,
        payload: error.response.data.message,
      });
    }
  };


  // Get ALL Enquiry in ADMIN
  export const getAllEnquiries = () => async (dispatch) => {
    try {
      dispatch({ type:ALL_ENQUIRY_REQUEST });
  
      const { data } = await axios.get(`/api/v1/admin/enquiries`);
  
      dispatch({
        type: ALL_ENQUIRY_SUCCESS,
        payload: data.enquiries,
      });
    } catch (error) {
      dispatch({
        type: ALL_ENQUIRY_FAIL,
        payload: error.response.data.message,
      });
    }
  };


//Delete Enquiry --- Admin
export const deleteEnquiry = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_ENQUIRY_REQUEST });
  
      const { data } = await axios.delete(`/api/v1/admin/enquiry/${id}`);
  
      dispatch({
        type: DELETE_ENQUIRY_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: DELETE_ENQUIRY_FAIL,
        payload: error.response.data.message,
      });
    }
  };


  //Clearing Errors
export const clearErrors = ()=> async (dispatch)=>{
    dispatch({ type: CLEAR_ERRORS });
};