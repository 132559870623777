import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userAction";
import logo from "../Components/Navbar/Rectangle.png";

const Login = () => {
  let navigate = useNavigate();

  const { loading, isAuthenticated } = useSelector((state) => state.user);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(login(username, password));
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [dispatch, navigate, isAuthenticated]);

  return (
    <div className=" h-screen py-24 ">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex  flex-col max-w-md p-6 ml-auto mr-auto rounded-md sm:p-10 bg-[#071738] text-gray-100">
          <div className="mb-8 text-center">
            <img
              src={logo}
              alt="logo"
              className=" md:py-0 w-52 md:w-52 mx-auto"
            />
            <h1 className="my-3 text-4xl font-bold">Sign in</h1>
            <p className="text-sm text-gray-400">
              Sign in to access your dashboard
            </p>
          </div>
          <form
            onSubmit={handleLogin}
            action=""
            className="space-y-12 ng-untouched ng-pristine ng-valid"
          >
            <div className="space-y-4">
              <div>
                <label htmlFor="Login-input-user" className="block mb-2 text-sm">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  placeholder="Your Username"
                  autoComplete="username"
                  className="w-full px-3 py-2 border rounded-md border-gray-700 bg-[#071738] text-gray-100"
                  required
                  value={username}
                  onChange={onChangeUsername}
                />
              </div>
              <div>
                <label htmlFor="login-input-password" className="text-sm">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="*****"
                  autoComplete="current-password"
                  className="w-full px-3 py-2 border rounded-md border-gray-700 bg-[#071738] text-gray-100"
                  required
                  value={password}
                  onChange={onChangePassword}
                />
              </div>
            </div>
            <div className="space-y-2">
              <div>
                <button
                  type="submit"
                  className="w-full px-8 py-3 font-semibold rounded-md bg-[#214dab] hover:bg-[#0f378d] text-white"
                  value="Login"
                >
                  Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
