import React, { useEffect, useState  } from 'react'
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert'

import { clearErrors, newContact } from '../../actions/contactAction'
import { CONTACT_RESET } from '../../constants/contactConstants'
import { useDispatch } from 'react-redux';

const OpeningHours = () => {

  const {error,success} = useSelector((state)=> state.newContact);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const alert = useAlert();


  useEffect(() => {
    if (error) {
        alert.error(error);
        dispatch(clearErrors());
    }
    if (success) {
        alert.success("Message send successfully");
        dispatch({ type: CONTACT_RESET});
    }
  }, [dispatch, alert, error, success]);


  const contactSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("message", message);

    dispatch(newContact(myForm));
  }

  return (



<section className="text-gray-600 body-font relative bg-white">
  
<div className="max-w-[90%] md:container pt-24  mx-auto">

<div className=" grid grid-cols-1 md:grid-cols-3 bg-gray-200 rounded-2xl p-4 ">
    <div className="grid grid-cols-1 md:grid-cols-2 col-span-2 bg-gray-200 gap-14 md:mr-4 rounded-xl p-8">
        <div className="">
            <h3 className='mb-4 mt-1 text-xl font-semibold'>Showroom Hours</h3>
            <div className="grid grid-cols-3 text-[1rem]">
                <div className="my-1">Monday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>07:00 PM</span> </div>
                <div className="my-1">Tuesday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>07:00 PM</span> </div>
                <div className=" my-1">Wednesday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>07:00 PM</span> </div>
                <div className="my-1">Thursday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>07:00 PM</span> </div>
                <div className="my-1">Friday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>06:00 PM</span> </div>
                <div className="my-1">Saturday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>05:00 PM</span> </div>
                <div className="my-1">Sunday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>05:00 PM</span> </div>
            </div>
        </div>
        <div className="">
        <h3 className='mb-4 mt-1 text-xl font-semibold'>Service Hours</h3>
        <div className="grid grid-cols-3 ">
                <div className="my-1">Monday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>08:00 AM</span> <span className='ml-6'>05:00 PM</span> </div>
                <div className="my-1">Tuesday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>08:00 AM</span> <span className='ml-6'>05:00 PM</span> </div>
                <div className="my-1">Wednesday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>08:00 AM</span> <span className='ml-6'>05:00 PM</span> </div>
                <div className="my-1">Thursday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>08:00 AM</span> <span className='ml-6'>05:00 PM</span> </div>
                <div className="my-1">Friday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>08:00 AM</span> <span className='ml-6'>05:00 PM</span> </div>
                <div className="my-1">Saturday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>09:00 AM</span> <span className='ml-6'>02:00 PM</span> </div>
                <div className="my-1">Sunday</div>
                <div className="col-span-2 my-1 justify-self-end"><span>Closed</span> </div>
            </div>
        </div>
    </div>
    <div className="bg-white grid  p-4 w-full  rounded-xl">
    <h3 className='my-4 text-xl font-semibold'> Contact Us </h3>
        <form onSubmit={contactSubmitHandler} className="md:w-full bg-white flex flex-col md:ml-auto w-full ">
      <div  className="relative mb-4">
        <input type="text" id="name" name="name" value={name} required className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder='Full name' onChange={(e) => setName(e.target.value)}/>
      </div>
      <div className="relative mb-4">
        <input type="email" value={email} id="email" name="email" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" required placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="relative mb-4">
        <textarea id="message" value={message} name="message" className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-28 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" required placeholder='Message'onChange={(e) => setMessage(e.target.value)}></textarea>
      </div>
      <button type="submit" className="text-white bg-[#315FCA] border-0 py-2 px-6 focus:outline-none hover:bg-[#254DAA] rounded text-lg">Submit</button>
    </form>
    </div>
</div>
</div>




</section>


  )
}

export default OpeningHours;