import {
    CONTACT_FAIL,
    CONTACT_REQUEST,
    CONTACT_RESET,
    CONTACT_SUCCESS,
    DELETE_CONTACT_FAIL,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_RESET,
    DELETE_CONTACT_SUCCESS,
    ALL_CONTACT_FAIL,
    ALL_CONTACT_REQUEST,
    ALL_CONTACT_SUCCESS,
    CLEAR_ERRORS,
} from "../constants/contactConstants";



export const newContactReducer = (state = { contact:{} }, action) => {
    
    switch (action.type) {
      case  CONTACT_REQUEST:
        return {
          loading: true,
          ...state,
        };
      case CONTACT_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          contact: action.payload.contact,
        };
      case CONTACT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case CONTACT_RESET:
        return {
          ...state,
          success:false,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };



  export const allContactsReducer = (state = {  contacts:[] }, action) => {

    switch (action.type) {
        case ALL_CONTACT_REQUEST:
            case DELETE_CONTACT_REQUEST:
            return {
                ...state,
                loading:true,
            };
        case ALL_CONTACT_SUCCESS:
            return {
                ...state,
                loading:false,
                contacts: action.payload,
            };
            case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
          case ALL_CONTACT_FAIL:
            case DELETE_CONTACT_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            };
            case DELETE_CONTACT_RESET:
      return {
        ...state,
        isDeleted:false,
      };
        case CLEAR_ERRORS:
            return {
                ...state,
                error:null,
            };
        
        default:
         return state;
    }
}