import React, { Fragment } from 'react'
import Routers from '../../Routers/Routers'
import { useLocation } from "react-router-dom";
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';

function Layout() {
  let location = useLocation();

  //Prevent Inspect
  // window.addEventListener("contextmenu", (e) => e.preventDefault());


  return (
    <Fragment>

        {location.pathname === '/dashboard' || location.pathname === '/admin-login' || location.pathname === '/dashboard/inventory' || location.pathname === '/dashboard/add-car' || location.pathname === `/dashboard/inventory/:id` || location.pathname === `/dashboard/enquiry` || location.pathname === `/dashboard/contact-form` || location.pathname === `/dashboard/contact-developer` || location.pathname === `/dashboard/all-careers` || location.pathname.startsWith(`/dashboard/inventory/`) ? null : <Navbar />}

 
        <Routers />

        {location.pathname === '/dashboard' || location.pathname === '/admin-login' || location.pathname === '/dashboard/inventory' || location.pathname === '/dashboard/add-car' || location.pathname === `/dashboard/inventory/:id` || location.pathname === `/dashboard/enquiry` || location.pathname === `/dashboard/contact-form` || location.pathname === `/dashboard/contact-developer`  || location.pathname === `/dashboard/all-careers` || location.pathname.startsWith(`/dashboard/inventory/`) ? null : <Footer />}


    </Fragment>
    
  )

  // return (
  //   <Fragment>
      
  //     <AdminRouters />
  //       {location.pathname !== '/dashboard' && location.pathname !== '/admin-login'  && location.pathname !== '/dashboard/inventory' && location.pathname !== '/dashboard/add-car' && location.pathname !== `/dashboard/inventory/:id` && location.pathname !== `/dashboard/enquiry` && <Navbar />}
 
  //       <Routers />
  //       {location.pathname !== '/dashboard' && location.pathname !== '/admin-login' && location.pathname !== '/dashboard/inventory' && location.pathname !== '/dashboard/add-car' && location.pathname !== `/dashboard/inventory/:id` && location.pathname !== `/dashboard/enquiry` && <Footer />}

  //   </Fragment>
    
  // )
}

export default Layout