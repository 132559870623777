import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Helmet from "../Components/Helmet/Helmet";
import { useAlert } from "react-alert";

import { clearErrors, newContact } from "../actions/contactAction";
import { CONTACT_RESET } from "../constants/contactConstants";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const { error, success } = useSelector((state) => state.newContact);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Message send successfully");
      dispatch({ type: CONTACT_RESET });
    }
  }, [dispatch, alert, error, success]);

  const contactSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("message", message);

    dispatch(newContact(myForm));
  };

  return (
    <Helmet title="Contact Us">
      {/* <--- Breadcrumb ---> */}

      <nav
        aria-label="breadcrumb"
        className="w-full p-4 bg-[#071738] text-gray-100"
      >
        <ol className="flex h-8 space-x-2">
          <li className="flex items-center">
            <Link
              to={"/home"}
              title="Back to homepage"
              className="hover:underline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 pr-1 text-gray-400"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
            </Link>
          </li>
          <li className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              aria-hidden="true"
              fill="currentColor"
              className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-600"
            >
              <path d="M32 30.031h-32l16-28.061z"></path>
            </svg>
            <Link
              to={"/contactus"}
              className="flex items-center px-1 capitalize hover:underline"
            >
              Contact Us
            </Link>
          </li>
        </ol>
      </nav>

      {/* <--- Breadcrumb ---> */}

      <section className="text-gray-600 body-font relative bg-white">
        <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0 google-map"
              title="map"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.8990898724537!2d-73.07903248480847!3d41.289300610033386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e87557ee65c8cd%3A0xab77c34e9a42c67!2s468%20River%20Rd%2C%20Shelton%2C%20CT%2006484%2C%20USA!5e0!3m2!1sen!2sin!4v1675707593281!5m2!1sen!2sin"
            ></iframe>
            <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">468 River Rd Shelton CT 06484</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a href="/" className="text-[#315FCA] leading-relaxed">
                  truth2it@truth2it.com
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p className="leading-relaxed">203-903-1544</p>
              </div>
            </div>
          </div>
          <form
            onSubmit={contactSubmitHandler}
            className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
          >
            <h2 className="text-gray-900 text-lg mb-1 font-semibold title-font">
              Contact Us
            </h2>
            <p className="leading-relaxed mb-5 text-gray-600">
              If you have any questions or concerns about our inventory,
              financing options, or any other aspect of our service, please
              don't hesitate to contact us. You can reach us by phone, email or
              by filling out the form on this page and we'll get back to you as
              soon as possible. Thank you for considering us for your next
              preowned vehicle purchase.
            </p>
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                required
                className="w-full bg-white rounded border border-gray-300 focus:border-[#315FCA] focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                value={email}
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-[#315FCA] focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                Message
              </label>
              <textarea
                id="message"
                value={message}
                name="message"
                className="w-full bg-white rounded border border-gray-300 focus:border-[#315FCA] focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                required
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button
              type="submit"
              className="text-white bg-[#315FCA] border-0 py-2 px-6 focus:outline-none hover:bg-[#254DAA] rounded text-lg"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </Helmet>
  );
};

export default ContactUs;
