import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../Navbar/Rectangle.png"
import {RiFacebookFill} from 'react-icons/ri'
import {AiFillInstagram} from 'react-icons/ai'
import {FaYoutube} from 'react-icons/fa'




const Footer = () => {
  return (
    
    <footer className=" bg-gray-100">
    <div className="container m-auto space-y-8 px-6 py-10 text-gray-600 md:px-12 lg:px-20">
      <div className="flex flex-wrap items-center justify-between gap-4 border-b pb-8">
      <Link to={"/"}>
                <img
                  src={logo}
                  alt="logo"
                  className=" md:py-0 w-52 md:w-52"
                />
              </Link>
        <div className="flex gap-6">
          <a href="/" target="blank" aria-label="github" className="hover:text-[#315FCA]">
            <RiFacebookFill className='w-6 h-6'/>
            
          </a>
          <a href="/" target="blank" aria-label="twitter" className="hover:text-[#315FCA]">
          <FaYoutube className='w-6 h-6'/>

          </a>
          <a href="/" target="blank" aria-label="medium" className="hover:text-[#315FCA]">
            <AiFillInstagram className='w-6 h-6'/>
          </a>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
        <div>
          <h6 className="text-lg font-medium text-[#224185]">Anthony's Hightech Auto Center Inc.</h6>
          <ul className="mt-4 list-inside space-y-4">
            <li>
              <Link to="/aboutus" className="transition hover:text-[#315FCA]">About</Link>
            </li>
            <li>
              <Link to="/contactus" className="transition hover:text-[#315FCA]">Contact</Link>
            </li>
            <li>
              <Link to="/careers" className="transition hover:text-[#315FCA]">Careers</Link>
            </li>
          </ul>
        </div>
        <div>
          <h6 className="text-lg font-medium text-[#224185]">Quick Links</h6>
          <ul className="mt-4 list-inside space-y-4">
            <li>
              <Link to="/inventory" className="transition hover:text-[#315FCA]">Inventory</Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="transition hover:text-[#315FCA]">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/driving-directions" className="transition hover:text-[#315FCA]">Driving Directions</Link>
            </li>
            
          </ul>
        </div>
        <div>
          <h6 className="text-lg font-medium text-[#224185]">Contact</h6>
          <ul className="mt-4 list-inside space-y-4">
            <li className='flex'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 self-center h-5">
  <path fillRule="evenodd" d="M4.5 2.25a.75.75 0 000 1.5v16.5h-.75a.75.75 0 000 1.5h16.5a.75.75 0 000-1.5h-.75V3.75a.75.75 0 000-1.5h-15zM9 6a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm-.75 3.75A.75.75 0 019 9h1.5a.75.75 0 010 1.5H9a.75.75 0 01-.75-.75zM9 12a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H9zm3.75-5.25A.75.75 0 0113.5 6H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM13.5 9a.75.75 0 000 1.5H15A.75.75 0 0015 9h-1.5zm-.75 3.75a.75.75 0 01.75-.75H15a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zM9 19.5v-2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v2.25a.75.75 0 01-.75.75h-4.5A.75.75 0 019 19.5z" clipRule="evenodd" />
</svg>
 <span className='pl-2'> 468 River Rd, Shelton, CT 06484, USA</span>
            </li>
            <li className='flex'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 self-center h-5">
  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
</svg>

              <a href="mailto:truth2it@truth2it.com" className="transition pl-2 hover:text-[#315FCA]"> truth2it@truth2it.com</a>
            </li>
            <li className='flex'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 self-center h-5">
  <path fillRule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clipRule="evenodd" />
</svg>

              <a href="tel:203-903-1544" className="transition pl-2 hover:text-[#315FCA]">203-903-1544</a>
            </li>
          </ul>
        </div>
        
      </div>
      <div className="flex justify-between rounded-full text-xs items-center sm:text-base bg-gray-200 px-4 py-3 text-gray-600">
        <span>All rights reserved. &copy;Anthony's Hightech Auto Center Inc.<span id="year"></span></span>
        <a target="_blank" rel="noreferrer" href="https://www.truth2it.com/" className="font-bold text-sm  sm:text-base text-right transition hover:text-[#315FCA]"><span className="font-medium  transition hover:text-[#315FCA]">Designed and developed by </span>Truth2it</a>
      </div>
    </div>
  </footer>
  
                                      
  )
}

export default Footer