import React, { Fragment, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearErrors, createCar } from '../actions/carAction'
import { NEW_CAR_RESET } from '../constants/carConstants'
import Sidebar from './Sidebar'
import Helmet from '../Components/Helmet/Helmet';


const NewCar = () => {
    const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const {loading,error,success} = useSelector((state)=> state.newCar);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [make, setMake] = useState("");
  const [description, setDescription] = useState("");
  const [year, setYear] = useState("");
  const [mileage, setMileage] = useState("");
  const [transmission, setTransmission] = useState("");
  const [model, setModel] = useState("");
  const [engine, setEngine] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [vin, setVin] = useState("");
  const [exteriorColor, setExteriorColor] = useState("");
  const [interiorColor, setInteriorColor] = useState("");
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [bodyType, setBodyType] = useState([]);

  const bodyTypes = [
    "Sedan",
    "Coupe",
    "Minivan",
    "Wagon",
    "Sports",
    "Hatchback",
    "CUV",
    "SUV",
    "MUV",
    "Pickup Trucks",
    "Convertible",
  ]

  useEffect(() => {
    if (error) {
        alert.error(error);
        dispatch(clearErrors());
    }
    if (success) {
        alert.success("Car Listing created succesfully");
        navigate("/dashboard/inventory");
        dispatch({ type: NEW_CAR_RESET});
    }
  }, [dispatch, alert, error,navigate, success])

  const createCarSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("price", price);
    myForm.set("description", description);
    myForm.set("make", make);
    myForm.set("model", model);
    myForm.set("year", year);
    myForm.set("mileage", mileage);
    myForm.set("engine", engine);
    myForm.set("transmission", transmission);
    myForm.set("fuelType", fuelType);
    myForm.set("vin", vin);
    myForm.set("exteriorColor", exteriorColor);
    myForm.set("interiorColor", interiorColor);
    myForm.set("bodyType", bodyType);


    images.forEach((image) => {
        myForm.append("images", image)
    });
    dispatch(createCar(myForm));
  }

const createCarImagesChange = (e) =>{
    const files = Array.from(e.target.files)
    setImages([]);
    setImagesPreview([]);

    files.forEach((file) =>{
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImagesPreview((old) => [...old, reader.result]);
                setImages((old => [...old, reader.result]))
            };
        };

        reader.readAsDataURL(file);
    })
};



  return (
    <Helmet title={'Add Car'}>

    <Fragment>
     


     <div className="grid h-screen bg-slate-800 ">
  <div className=" overflow-hidden">
  
    <div className="grid grid-cols-5 h-screen">
      <nav className="grid col-span-1 h-full">
      <Sidebar />
        
      </nav>
      <main className="grid col-span-4 h-screen  bg-[#071738] overflow-x-hidden overflow-y-auto mb-14">
        
      <div className="container p-2 mx-auto sm:p-4 text-gray-100">
      <form onSubmit={createCarSubmitHandler} className="w-full max-w-5xl p-8 mx-auto space-y-6 rounded-md shadow bg-[#010d23] ng-untouched ng-pristine ng-valid ">
		<h2 className="w-full text-3xl font-bold leading-tight">Add Car</h2>
        <div className="grid grid-cols-2 gap-8">
		<div>
			<label className="block mb-1 ml-1">Title/Name</label>
			<input id="carName" type="text" placeholder="Eg: BMW 7 Series" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800  text-white" value={name} onChange={(e) => setName(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Make</label>
			<input id="name" type="text" placeholder="Eg: BMW" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800  text-white" value={make} onChange={(e) => setMake(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Model</label>
			<input id="name" type="text" placeholder="Eg:   7 Series" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white" value={model} onChange={(e) => setModel(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Year</label>
			<input id="name" type="text" placeholder="Eg: 2023" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white" value={year} onChange={(e) => setYear(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Mileage</label>
			<input id="name" type="text" placeholder="Eg: 4321" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            value={mileage} onChange={(e) => setMileage(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Body Type</label>
            <select 
            className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            onChange={(e) => setBodyType(e.target.value)} >
                <option value="">Select Bodytype</option>
                {bodyTypes.map((body)=> (
                    <option key={body} value={body}>
                        {body}
                    </option>
                ))}
            </select>
		</div>
        <div>
			<label className="block mb-1 ml-1">Transmission</label>
			<input id="name" type="text" placeholder="Eg: Manual/Automatic" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            value={transmission} onChange={(e) => setTransmission(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Engine</label>
			<input id="name" type="text" placeholder="Eg: V8" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            value={engine} onChange={(e) => setEngine(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Fuel Type</label>
			<input id="name" type="text" placeholder="Eg: Gas/Diesel" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            value={fuelType} onChange={(e) => setFuelType(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">VIN</label>
			<input id="name" type="text" placeholder="Eg: 1234567890" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            value={vin} onChange={(e) => setVin(e.target.value)} />
		</div>
        <div>
			<label  className="block mb-1 ml-1">Exterior Color</label>
			<input id="name" type="text" placeholder="Eg: Coral Blue" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            value={exteriorColor} onChange={(e) => setExteriorColor(e.target.value)} />
		</div>
        <div>
			<label className="block mb-1 ml-1">Interior Color</label>
			<input id="name" type="text" placeholder="Eg: Beige" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white"
            value={interiorColor} onChange={(e) => setInteriorColor(e.target.value)} />
		</div>
        <div className='col-span-2'>
			<label className="block mb-1 ml-1 ">Price</label>
			<input id="name" type="number" inputMode='numeric' placeholder="Price" required className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white" value={price} onChange={(e) => setPrice(e.target.value)} /> 
		</div>
		<div className='col-span-2'>
			<label className="block mb-1 ml-1">Description</label>
			<textarea row='4' id="message" type="text" placeholder="Description..." className="block w-full p-2 rounded autoexpand focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#467bed] bg-gray-800 text-white" value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
		</div>
</div>

        <fieldset className="w-full space-y-1 text-gray-100">
	<label htmlFor="files" className="block text-sm font-medium">Images</label>
	<div className="border-2 border-dashed rounded-md border-gray-700  bg-gray-800">
		<input type="file" name="files" id="files" accept='image/*' multiple onChange={createCarImagesChange} className="px-8 py-12 w-full  object-cover text-white" />
        <div className='flex gap-4 overflow-x-auto px-8 pb-2'>
            {imagesPreview.map((image, index)=> (
                <img key={index} src={image} alt="Images" className='h-16 w-16 rounded-md'/>
            ))}
        </div>
	</div>
</fieldset>
		<div>
			<button type="submit" className="w-full px-4 py-2 font-bold rounded shadow focus:outline-none focus:ring hover:ring focus:ring-opacity-50 bg-[#467bed] focus:ring-[#467bed] hover:ring-[#467bed]  text-white" disabled={loading ? true : false} >Create Listing</button>
		</div>
	</form>


 </div>
      </main>
     
        
      </div>
    </div>
  </div>

    </Fragment>
    </Helmet>
  )
}

export default NewCar