import {legacy_createStore as createStore,combineReducers,applyMiddleware} from "redux";

import thunk from "redux-thunk";

import {composeWithDevTools} from "redux-devtools-extension";
import { carDetailsReducer, carsReducer, newCarReducer,carReducer } from "./reducers/carReducer";
import { userReducer } from "./reducers/userReducer";
import { allEnquiriesReducer, newEnquiryReducer } from "./reducers/enquiryReducer";
import { allContactsReducer, newContactReducer } from "./reducers/contactReducer";
import { allCareersReducer, newCareerReducer } from "./reducers/careerReducer";




const reducer = combineReducers({
    cars: carsReducer,
    carDetails: carDetailsReducer,
    user:userReducer,
    newCar: newCarReducer,
    car: carReducer,
    newEnquiry: newEnquiryReducer,
    allEnquiries: allEnquiriesReducer,
    newContact: newContactReducer,
    allContacts: allContactsReducer,
    newCareer: newCareerReducer,
    allCareers: allCareersReducer
});




let initialState = {};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
    );


    export default store;