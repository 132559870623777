import React from "react";
import Helmet from "../Components/Helmet/Helmet";
import Map from "../Components/Map/Map";

const DrivingDirections = () => {
  return (
    <Helmet title="Driving Directions">
      <Map />
    </Helmet>
  );
};

export default DrivingDirections;
