import React, { Fragment } from 'react'
import Sidebar from './Sidebar'
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { clearErrors,getAdminCar, deleteCar } from "../actions/carAction";
import { useAlert } from "react-alert";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DELETE_CAR_RESET } from '../constants/carConstants';
import Helmet from '../Components/Helmet/Helmet';


const AdminInventory = () => {

    const dispatch = useDispatch();
        const alert = useAlert();
  const navigate = useNavigate();

        const {error,cars} = useSelector((state) => state.cars)

        const { error: deleteError, isDeleted} = useSelector((state) => state.car)

        const deleteCarHandler = (id) => {
            dispatch(deleteCar(id));
        };
    
        useEffect(() => {
            if (error) {
                alert.error(error);
                dispatch(clearErrors());
            }
            if (deleteError) {
                alert.error(deleteError);
                dispatch(clearErrors());
            }
            if (isDeleted) {
                alert.success("Car Listing Deleted succesfully");
                navigate("/dashboard/inventory");
                dispatch({ type: DELETE_CAR_RESET });
            }
            

            dispatch(getAdminCar());
        }, [dispatch,alert,error,deleteError,navigate,isDeleted]);


  return (
    <Helmet title={'Inventory'}>

    <Fragment>
     


     <div className="grid h-screen bg-slate-800 ">
  <div className=" overflow-hidden">
  
    <div className="grid grid-cols-5 h-screen">
      <nav className="grid col-span-1  h-full">
      <Sidebar />
        
      </nav>
      <main className="grid col-span-4 h-screen  bg-[#071738] overflow-x-hidden overflow-y-auto mb-14">
      <div className="bg-[#010d23] py-4 self-center fixed top-0 w-full">

<h2 className="text-2xl px-8 text-gray-100 font-semibold leading-tight">Inventory</h2>
      </div>
      <div className="container mt-16 mx-auto text-gray-100">
 	<div className="overflow-x-auto ">
 		<table className="min-w-full text-xs ">
         <thead className="bg-[#2f5bb5] font-medium">
 				<tr className="text-left ">
 					<th className="p-3 py-4">Name</th>
 					<th className="p-3">Make</th>
 					<th className="p-3">Year</th>
 					<th className="p-3">Mileage</th>
 					<th className="p-3">Engine</th>
 					<th className="p-3">Transmission</th>
 					<th className="p-3">VIN</th>
 					<th className="p-3 ">Price</th>
 					<th className="p-3 ">Date Created</th>
 					<th className="p-3 pr-6 text-right">Actions</th>
 				</tr>
 			</thead>
 			{cars.map(car => (
          <Fragment key={car._id}>
          <tbody>
                      <tr className="border-b border-opacity-20 border-gray-700 bg-[#071738] hover:bg-[#46609c56]">
                          <td className="p-3">
                              <p>{car.name}</p>
                          </td>
                          <td className="p-3">
                              <p>{car.make}</p>
                          </td>
                <td className="p-3">
                              <p>{car.year}</p>
                          </td>
                          <td className="p-3">
                              <p>{car.mileage}</p>
                          </td>
                          <td className="p-3">
                              <p>{car.engine}</p>
                          </td>
                <td className="p-3">
                              <p>{car.transmission}</p>
                          </td>
                          <td className="p-3">
                              <p>{car.vin}</p>
                          </td>
                          <td className="p-3">
                              <p>{car.price}</p>
                          </td>
                          <td className="p-3">
                              <p>{car.createdAt.slice(0,10)}</p>
                          </td>
                          <td className="p-3 text-right text-xl cursor-pointer">
                              
      
                              <Link to={`/dashboard/inventory/${car._id}`}>
                          <i title='Edit' className="ri-pencil-line hover:text-orange-400"></i>
                              </Link>
                              <button title='Delete' onClick={() => deleteCarHandler(car._id)} className='ml-4'>
                              <i className="ri-delete-bin-2-line px-2 text-red-400 hover:text-red-600"></i>
                              </button>
                              
                          </td>
                      </tr>
                      
                  </tbody>
                  </Fragment>
        ))}
			
			
 		</table>
 	</div>
 </div>
      </main>
      
        
      </div>
    </div>
  </div>

    </Fragment>
    </Helmet>
    )
}

export default AdminInventory