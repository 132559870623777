import React, { Fragment } from "react";
import { NavLink,Link } from "react-router-dom";
import Header from '../Header/Header';
import logo from "./Rectangle.png"

const Navbar = () => {
  return (
    <Fragment>
    <Header />

    <header>
      <div className="relative z-20 border-b bg-white md:sticky  top-0">
        <div className="px-6 md:px-12 lg:container lg:mx-auto lg:px-6 lg:py-4">
          <div className="flex items-center justify-between">
            <div className="relative z-20">
              <a href="/">
                <img
                  src={logo}
                  alt="logo"
                  className="py-2 md:py-0 w-52 md:w-52"
                />
              </a>
            </div>

            <div className="flex items-center justify-end border-l lg:border-l-0">
              <input
                type="checkbox"
                name="hamburger"
                id="hamburger"
                className="peer"
                hidden
              />
              <label
                htmlFor="hamburger"
                className="peer-checked:hamburger block relative z-20 p-6 -mr-6 cursor-pointer lg:hidden"
              >
                <div
                  aria-hidden="true"
                  className="m-auto h-0.5 w-6 rounded bg-[#224185] transition duration-300"
                ></div>
                <div
                  aria-hidden="true"
                  className="m-auto mt-2 h-0.5 w-6 rounded bg-[#224185] transition duration-300"
                ></div>
              </label>

              <div className="peer-checked:translate-x-0 fixed inset-0 w-[calc(100%-4.5rem)] translate-x-[-100%] bg-white border-r shadow-xl transition duration-300 lg:border-r-0 lg:w-auto lg:static lg:shadow-none lg:translate-x-0">
                <div className="flex flex-col h-full justify-between lg:items-center lg:flex-row">
                  <ul className="px-6 pt-32 text-gray-700 space-y-8 md:px-12 lg:space-y-0 lg:flex lg:space-x-12 lg:pt-0">
                  <li className="max-w-max">
                      <NavLink to="/home" className="block md:px-3 group">
                        <div
                          className="relative text-[#224185]
                                                    before:absolute before:-bottom-2 md:before:-bottom-7 before:origin-left before:w-full before:h-0.5 before:mx-auto before:mt-auto before:rounded-full before:bg-[#E75F22] before:transition before:scale-x-0 group-hover:before:scale-x-100"
                        >
                          <span className="transition font-semibold group-hover:text-[#E75F22]">
                            Home
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="max-w-max">
                      <NavLink to="/inventory" className="block md:px-3 group">
                        <div
                          className="relative text-[#224185]
                                                    before:absolute before:-bottom-2 md:before:-bottom-7 before:origin-left before:w-full before:h-0.5 before:mx-auto before:mt-auto before:rounded-full before:bg-[#E75F22] before:transition before:scale-x-0 group-hover:before:scale-x-100"
                        >
                          <span className="transition font-semibold group-hover:text-[#E75F22]">
                            Inventory
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="max-w-max">
                      <NavLink to="/aboutus" className="block md:px-3 group">
                        <div
                          className="relative text-[#224185]
                                                    before:absolute before:-bottom-2 md:before:-bottom-7 before:origin-left before:w-full before:h-0.5 before:mx-auto before:mt-auto before:rounded-full before:bg-[#E75F22] before:transition before:scale-x-0 group-hover:before:scale-x-100"
                        >
                          <span className="transition font-semibold group-hover:text-[#E75F22]">
                            About Us
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="max-w-max">
                      <NavLink to="/contactus" className="block md:px-3 group">
                        <div
                          className="relative text-[#224185]
                                                    before:absolute before:-bottom-2 md:before:-bottom-7 before:origin-left before:w-full before:h-0.5 before:mx-auto before:mt-auto before:rounded-full before:bg-[#E75F22] before:transition before:scale-x-0 group-hover:before:scale-x-100"
                        >
                          <span className="transition font-semibold group-hover:text-[#E75F22]">
                            Contact Us
                          </span>
                        </div>
                      </NavLink>
                    </li>
                    <li className="max-w-max">
                      <NavLink to="/careers" className="block md:px-3 group">
                        <div
                          className="relative text-[#224185]
                                                    before:absolute before:-bottom-2 md:before:-bottom-7 before:origin-left before:w-full before:h-0.5 before:mx-auto before:mt-auto before:rounded-full before:bg-[#E75F22] before:transition before:scale-x-0 group-hover:before:scale-x-100"
                        >
                          <span className="transition font-semibold group-hover:text-[#E75F22]">
                            Careers
                          </span>
                        </div>
                      </NavLink>
                    </li>
                  </ul>

                  <div className="border-t py-8 px-6 md:px-12 md:py-16 lg:border-t-0 lg:border-l lg:py-0 lg:pr-0 lg:pl-6">
                    <Link
                      to={"/contactus"}
                      className="block px-6 py-3 rounded-full bg-gradient-to-r from-[#224185] to-[#2750A4] text-center text-white"
                    >
                      Enquiry Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    </Fragment>
  );
};

export default Navbar;
