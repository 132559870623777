import React from 'react'
import heroImg from "./hero-img.png";
import heroBg from "./hero-bg.png"
import { Link } from 'react-router-dom';

const HeroSection = () => {

  return (

    <div className="min-h-screen flex bg-cover bg-center" style={{ backgroundImage: `url(${heroBg})` }}>
            <div className="container m-auto px-6 pb-40 pt-20 md:px-12 lg:py-0 lg:px-7">
                <div className="flex items-center flex-wrap gap-12 lg:gap-0">
                    <div className="lg:w-5/12 space-y-8">
                        <h1 className="text-4xl font-bold md:text-6xl text-[#224185]">Easy way to find <br /> the right car!</h1>
                        <p className="text-xl  text-gray-500">Drive Your Dream Car at Our Preowned Dealership. Wide Selection, Competitive Prices, Test Drive Today!</p>
                        
                        <div className="flex space-x-4">
                            <button type="button" title="Start buying" className="w-full py-3 px-6 text-center transition bg-[#224185]  hover:bg-[#071738] active:bg-gray-700 focus:bg-gray-800 sm:w-max">
                                <Link to={'/contactus'} className="block text-white font-semibold">
                                    Contact Now
                                </Link>
                            </button>
                            <button type="button" title="Start buying" className="group w-full py-3 px-6 text-center transition active:bg-gray-700 focus:bg-gray-700  sm:w-max">
                            <Link to={"/inventory"}>
                                <span className="block text-[#224185] font-semibold group-focus:text-white">
                                    View Inventory
                                </span></Link>
                            </button>
                        </div>
                    </div>

                    <div className=" relative md:block lg:w-7/12">
                        <img src={heroImg} className="relative ml-auto mb-[-5rem]" alt=""/>
                    </div>
                </div>
            </div>
           
        </div>


  )
}

export default HeroSection