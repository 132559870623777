import React, { Fragment } from "react";
import AboutUs from "../Components/AboutUs/AboutUs";
import { ParallaxProvider } from "react-scroll-parallax";
import AllBrands from "../Components/AllBrand/AllBrands";
import HeroSection from "../Components/HeroSlider/HeroSection";
import CarSearch from "../Components/CarSearch/CarSearch";
import Helmet from "../Components/Helmet/Helmet";
import OpeningHours from "../Components/OpeningHours/OpeningHours";
import Map from "../Components/Map/Map";
import Cta from "../Components/CTA/Cta";
import CarTow from "../Components/CarTow/CarTow";

function Home() {
  
  return (
    <Helmet title="Home">
      <Fragment>
        <ParallaxProvider>
          <HeroSection />
          <CarSearch />
          <AboutUs />
          <Cta />
          <CarTow/>
          <AllBrands />
          <OpeningHours />
          <Map />
        </ParallaxProvider>
      </Fragment>
    </Helmet>
  );
}

export default Home;
