import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";

import { useSelector, useDispatch } from "react-redux";
import { getCarDetails } from "../actions/carAction";
import { useParams } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "../Components/ImageSlider/lightbox.css";
import Loader from "../Components/Loader/Loader";
import Helmet from "../Components/Helmet/Helmet";
import { useNavigate } from "react-router-dom";

import { clearErrors, newEnquiry } from "../actions/enquiryAction";
import { ENQUIRY_RESET } from "../constants/enquiryConstants";

const CarDetail = () => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, car } = useSelector((state) => state.carDetails);
  const carImage = car && car.images && car.images.map((image) => image.url);

  const alert = useAlert();
  const navigate = useNavigate();

  const { error, success } = useSelector((state) => state.newEnquiry);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const formattedAmount = currencyFormatter.format(car.price);

  useEffect(() => {
    dispatch(getCarDetails(id)).then(() => {});
  }, [dispatch, id]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Enquiry send successfully");
      dispatch({ type: ENQUIRY_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const [toggleState, setToggleState] = useState(1);

  const activeTab =
    "flex-grow text-[#315FCA] border-b-2 border-[#315FCA] py-2 text-lg px-1 cursor-pointer";
  const tabs =
    "flex-grow border-b-2 border-gray-300 py-2 text-lg px-1 cursor-pointer";

  const activeContent = "block";
  const content = "hidden";

  const toggleTab = (index) => {
    setToggleState(index);
  };


  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const enquirySubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("phone", phone);
    myForm.set("message", message);
    myForm.set("enquiryFor", car.name);

    dispatch(newEnquiry(myForm));
  };

  const nextSlide = () => {
    setIsOpen(false);
    if (carImage) {
      if (currentIndex === carImage.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }
  };

  const prevSlide = () => {
    setIsOpen(false);
    if (carImage) {
      if (currentIndex === 0) {
        setCurrentIndex(carImage.length - 1);
      } else {
        setCurrentIndex(currentIndex - 1);
      }
    }
  };

  return (
    <Helmet title={car.name}>
      <Fragment>
        {/* <--- Breadcrumb ---> */}

        <nav
          aria-label="breadcrumb"
          className="w-full p-4 bg-[#071738] text-gray-100"
        >
          <ol className="flex h-8 space-x-2">
            <li className="flex items-center">
              <Link
                rel="noopener noreferrer"
                to={"/home"}
                title="Back to homepage"
                className="hover:underline"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 pr-1 text-gray-400"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
              </Link>
            </li>
            <li className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                fill="currentColor"
                className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-600"
              >
                <path d="M32 30.031h-32l16-28.061z"></path>
              </svg>
              <Link
                rel="noopener noreferrer"
                to={"/inventory"}
                className="flex items-center px-1 capitalize hover:underline"
              >
                Inventory
              </Link>
            </li>
            <li className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                fill="currentColor"
                className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-600"
              >
                <path d="M32 30.031h-32l16-28.061z"></path>
              </svg>
              <Link
                to={car.id}
                className="flex items-center px-1 capitalize hover:underline"
              >
                Car
              </Link>
            </li>
          </ol>
        </nav>

        {/* <--- Breadcrumb ---> */}

        {/* <--- CarDetails ---> */}
        {loading ? (
          <Loader />
        ) : (
          <section className="text-gray-600  body-font overflow-hidden bg-white">
            <div className=" px-5 py-24  mx-auto">
              <div className="lg:w-4/5 mx-auto flex  flex-wrap">
                <div className="lg:w-1/2 w-full ">
                  <div className="relative h-[20rem]  mt-8 rounded-xl overflow-hidden w-full ">
                    <button
                      className=" group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer z-10"
                      onClick={prevSlide}
                    >
                      <FaAngleLeft className="text-2xl" />
                    </button>
                    {carImage && (
                      <img
                        className={`w-full h-full object-cover transition duration-500 ease-in-out transform `}
                        src={carImage[currentIndex]}
                        key={currentIndex}
                        alt="Product"
                        onClick={() => setIsOpen(true)}
                      />
                    )}
                    {isOpen && (
                      <Lightbox
                        mainSrc={carImage[currentIndex]}
                        nextSrc={carImage[(currentIndex + 1) % carImage.length]}
                        prevSrc={
                          carImage[
                            (currentIndex + carImage.length - 1) %
                              carImage.length
                          ]
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                          setCurrentIndex(
                            (currentIndex + carImage.length - 1) %
                              carImage.length
                          )
                        }
                        onMoveNextRequest={() =>
                          setCurrentIndex((currentIndex + 1) % carImage.length)
                        }
                        className="z-20"
                      />
                    )}
                    <button
                      className=" group-hover:block absolute top-[50%] right-5 translate-x-0 translate-y-[-50%] text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
                      onClick={nextSlide}
                    >
                      <FaAngleRight className="text-2xl" />
                    </button>
                  </div>

                  <div className="flex justify-center scrollbar-hide overflow-x-auto hover:overflow-x-scroll">
                    {car.images &&
                      car.images.map((item, i) => (
                        <img
                          key={i}
                          onClick={() => {
                            setIsOpen(false);
                            setCurrentIndex(i);
                          }}
                          className={`w-24 h-24 rounded-md m-2 object-cover cursor-pointer ${
                            i === currentIndex ? "border-2 border-blue-500" : ""
                          }`}
                          src={item.url}
                          alt="Product"
                        />
                      ))}
                  </div>
                </div>

                <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mb-6 lg:mb-0">
                  <h2 className="text-sm title-font text-[#315FCA] tracking-widest">
                    {car.make}
                  </h2>
                  <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">
                    {car.name}
                  </h1>
                  <div className="flex mb-4">
                    <span
                      className={toggleState === 1 ? activeTab : tabs}
                      onClick={() => toggleTab(1)}
                    >
                      Specifications
                    </span>
                    <span
                      className={toggleState === 2 ? activeTab : tabs}
                      onClick={() => toggleTab(2)}
                    >
                      Description
                    </span>
                    <span
                      className={toggleState === 3 ? activeTab : tabs}
                      onClick={() => toggleTab(3)}
                    >
                      Enquiry Now
                    </span>
                  </div>

                  <div className={toggleState === 1 ? activeContent : content}>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Year:</span>
                      <span className="ml-auto text-gray-900">{car.year}</span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Mileage:</span>
                      <span className="ml-auto text-gray-900">
                        {car.mileage} miles
                      </span>
                    </div>
                    <div className="flex border-t border-gray-200 py-2">
                      <span className="text-gray-500">Engine:</span>
                      <span className="ml-auto text-gray-900">
                        {car.engine}
                      </span>
                    </div>
                    <div className="flex border-t border-b border-gray-200 py-2">
                      <span className="text-gray-500">Transmission:</span>
                      <span className="ml-auto text-gray-900">
                        {car.transmission}
                      </span>
                    </div>
                    <div className="flex border-t border-b border-gray-200 py-2">
                      <span className="text-gray-500">Exterior Color:</span>
                      <span className="ml-auto text-gray-900">
                        {car.exteriorColor}
                      </span>
                    </div>
                    <div className="flex border-t border-b border-gray-200 py-2">
                      <span className="text-gray-500">Interior:</span>
                      <span className="ml-auto text-gray-900">
                        {car.interiorColor}
                      </span>
                    </div>
                    <div className="flex border-t border-b mb-6 border-gray-200 py-2">
                      <span className="text-gray-500">VIN:</span>
                      <span className="ml-auto text-gray-900">{car.vin}</span>
                    </div>
                    <span className="title-font font-medium text-2xl text-gray-500">
                      Get it for
                    </span>
                    <span className="title-font font-semibold text-3xl text-[#315FCA]">
                      {" "}
                      {formattedAmount}
                    </span>
                  </div>

                  <div className={toggleState === 2 ? activeContent : content}>
                    <p className="leading-relaxed mb-4">{car.description}</p>
                  </div>
                  <div className={toggleState === 3 ? activeContent : content}>
                    <div className="flex">
                      <form
                        onSubmit={enquirySubmitHandler}
                        className="container w-full max-w-xl p-8 mx-auto space-y-6 rounded-md shadow bg-gray-100 ng-untouched ng-pristine ng-valid"
                      >
                        <div>
                          <input
                            id="name"
                            type="text"
                            placeholder="Your name"
                            value={name}
                            required
                            className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#315FCA] bg-gray"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div>
                          <input
                            id="email"
                            type="email"
                            placeholder="Your email"
                            value={email}
                            required
                            className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#315FCA] bg-gray"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div>
                          <input
                            id="contactNo"
                            type="tel"
                            placeholder="Your contact no."
                            value={phone}
                            required
                            className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#315FCA] bg-gray"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div>
                          <input
                            id="enquiryFor"
                            type="text"
                            value={car.name}
                            className="block w-full p-2 rounded focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#315FCA] bg-gray"
                          />
                        </div>
                        <div>
                          <textarea
                            id="message"
                            type="text"
                            value={message}
                            placeholder="Your message..."
                            className="block w-full p-2 rounded autoexpand focus:outline-none focus:ring focus:ring-opacity-25 focus:ring-[#315FCA] bg-gray "
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="w-full px-4 py-2 font-bold rounded shadow focus:outline-none focus:ring hover:ring focus:ring-opacity-50 bg-[#315FCA] focus:ring-[#315FCA] hover:ring-[#315FCA] text-white"
                          >
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* <--- CarDetails ---> */}
      </Fragment>
    </Helmet>
  );
};

export default CarDetail;
