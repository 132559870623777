import axios from "axios";

import {
    CONTACT_FAIL,
    CONTACT_REQUEST,
    CONTACT_SUCCESS,
    DELETE_CONTACT_FAIL,
    DELETE_CONTACT_REQUEST,
    DELETE_CONTACT_SUCCESS,
    ALL_CONTACT_FAIL,
    ALL_CONTACT_REQUEST,
    ALL_CONTACT_SUCCESS,
    CLEAR_ERRORS,
} from "../constants/contactConstants";


//Create Contact
export const newContact = (contactData) => async (dispatch) => {
    try {
      dispatch({ type:CONTACT_REQUEST });
  
      const config = {
        headers: {"Content-Type": "application/json"},
      };
      const { data } = await axios.post(`/api/v1/contact/new`,contactData,config);
  
      dispatch({
        type: CONTACT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_FAIL,
        payload: error.response.data.message,
      });
    }
  };


  // Get ALL Contacts in ADMIN
  export const getAllContacts = () => async (dispatch) => {
    try {
      dispatch({ type:ALL_CONTACT_REQUEST });
  
      const { data } = await axios.get(`/api/v1/admin/contacts`);
  
      dispatch({
        type: ALL_CONTACT_SUCCESS,
        payload: data.contacts,
      });
    } catch (error) {
      dispatch({
        type: ALL_CONTACT_FAIL,
        payload: error.response.data.message,
      });
    }
  };


//Delete Contact --- Admin
export const deleteContact = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_CONTACT_REQUEST });
  
      const { data } = await axios.delete(`/api/v1/admin/contact/${id}`);
  
      dispatch({
        type: DELETE_CONTACT_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: DELETE_CONTACT_FAIL,
        payload: error.response.data.message,
      });
    }
  };


  //Clearing Errors
export const clearErrors = ()=> async (dispatch)=>{
    dispatch({ type: CLEAR_ERRORS });
};