import React, { Fragment } from 'react'
import Sidebar from './Sidebar'
import { useEffect} from "react"
import { useSelector, useDispatch } from "react-redux";
import { clearErrors,getAllContacts,deleteContact } from "../actions/contactAction";
import { useAlert } from "react-alert";
import { DELETE_CONTACT_RESET } from '../constants/contactConstants';
import Helmet from '../Components/Helmet/Helmet';


const AllContact = () => {

    const dispatch = useDispatch();
        const alert = useAlert();

        const { error, contacts } = useSelector((state) => state.allContacts);
        const { error: deleteError, isDeleted} = useSelector((state) => state.allContacts)


        const deleteContactHandler = (id) => {
            dispatch(deleteContact(id));
        };

     const customSort = (a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            if (dateA < dateB) return 1;
            else if (dateA > dateB) return -1;
            return 0;
          };

          contacts.sort(customSort)




        useEffect(() => {
            if (error) {
              alert.error(error);
              dispatch(clearErrors());
            }
            if (deleteError) {
                alert.error(deleteError);
                dispatch(clearErrors());
            }
            if (isDeleted) {
                alert.success("Contact Deleted succesfully");
                dispatch({ type: DELETE_CONTACT_RESET });
            }
            dispatch(getAllContacts());
          }, [dispatch, alert, error,deleteError,isDeleted]);

          
          const downloadCSV = () => {


            const header = ['S. No.', 'Name', 'Email', 'Phone No.', 'Enquiry For', 'Message', 'Date'];
    const rows = contacts.map((e, index) => [`${index+1}`,e.name, e.email, e.phone, e.enquiryFor, e.message, e.createdAt.slice(0,10)]);
    const csv = [header, ...rows].map(r => r.join(',')).join('\n');
    const blob = new Blob([csv], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'All-Contacts.csv';
            link.click();
  };       
        

  return (
    <Helmet title={'All Contacts'}>
    <Fragment>
     


     <div className="grid h-screen bg-slate-800 ">
  <div className=" overflow-hidden">
  
    <div className="grid grid-cols-5 h-screen">
      <nav className="grid col-span-1 h-full">
      <Sidebar />
        
      </nav>
      <main className="col-span-4 h-screen  bg-[#071738] overflow-x-hidden overflow-y-auto mb-14">
      <div className="bg-[#010d23] container grid  mx-auto    ">
<div className=" grid py-4 grid-cols-2">
<h2 className="text-2xl px-8 text-gray-100 self-center font-semibold leading-tight">All Contacts</h2>
<span className='self-center justify-self-end pr-12 text-sm text-white '><button onClick={downloadCSV}className='bg-[#2f5bb5] hover:bg-[#0e1e3e] flex self-center gap-1 p-2 rounded-md'>Export CSV <i className="ri-file-download-line text-sm"></i></button>  </span>
</div>
      </div>
      <div className="container mx-auto text-gray-100">

 	<div className="overflow-x-auto">
 		<table className="min-w-full text-xs">
         <thead className="dark:bg-[#2f5bb5]">
 				<tr className="text-left">
 					<th className="p-3 py-4">Name</th>
 					<th className="p-3">Email</th>
 					<th className="p-3">Message</th>
 					<th className="p-3">Date</th>
 					<th className="p-3 text-right">Action</th>
 				</tr>
 			</thead>
             {contacts.map(contact => (
          <Fragment key={contact._id}>
          <tbody>
                      <tr className="border-b border-opacity-20 border-gray-700 bg-[#071738] hover:bg-[#46609c56]">
                          <td className="p-3">
                              <p>{contact.name}</p>
                          </td>
                          <td className="p-3">
                              <p>{contact.email}</p>
                          </td>
                          <td className="p-3 w-[40%]">
                              <p>{contact.message}</p>
                          </td>
                <td className="p-3">
                              <p>{contact.createdAt.slice(0,10)}</p>
                          </td>
                          
                          <td className="p-3 text-right text-xl cursor-pointer">
                              
                              <button title='Delete' onClick={() => deleteContactHandler(contact._id)} className='ml-4'>
                              <i className="ri-delete-bin-2-line text-red-400 px-2 hover:text-red-600"></i>
                              </button>
                              
                          </td>
                      </tr>
                      
                  </tbody>
                  </Fragment>
        ))}
			
			
 		</table>
 	</div>
 </div>
      </main>
      
        
      </div>
    </div>
  </div>

    </Fragment>
    </Helmet>
  )
}

export default AllContact