import {
    CAREER_FAIL,
    CAREER_REQUEST,
    CAREER_RESET,
    CAREER_SUCCESS,
    DELETE_CAREER_FAIL,
    DELETE_CAREER_REQUEST,
    DELETE_CAREER_RESET,
    DELETE_CAREER_SUCCESS,
    ALL_CAREER_FAIL,
    ALL_CAREER_REQUEST,
    ALL_CAREER_SUCCESS,
    CLEAR_ERRORS,
} from "../constants/careerConstants";



export const newCareerReducer = (state = { career:{} }, action) => {
    
    switch (action.type) {
      case  CAREER_REQUEST:
        return {
          loading: true,
          ...state,
        };
      case CAREER_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
          career: action.payload.career,
        };
      case CAREER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case CAREER_RESET:
        return {
          ...state,
          success:false,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };



  export const allCareersReducer = (state = {  careers:[] }, action) => {

    switch (action.type) {
        case ALL_CAREER_REQUEST:
            case DELETE_CAREER_REQUEST:
            return {
                ...state,
                loading:true,
            };
        case ALL_CAREER_SUCCESS:
            return {
                ...state,
                loading:false,
                careers: action.payload,
            };
            case DELETE_CAREER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
          case ALL_CAREER_FAIL:
            case DELETE_CAREER_FAIL:
            return {
                ...state,
                loading:false,
                error: action.payload,
            };
            case DELETE_CAREER_RESET:
      return {
        ...state,
        isDeleted:false,
      };
        case CLEAR_ERRORS:
            return {
                ...state,
                error:null,
            };
        
        default:
         return state;
    }
}