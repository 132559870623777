import React, { Fragment, useEffect, useState } from "react";
import { clearErrors, getCar } from "../actions/carAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Components/Loader/Loader";
import { useAlert } from "react-alert";
import { Link, useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import Car from "../Components/CarCard/Car";
import { useNavigate } from "react-router-dom";
import Helmet from "../Components/Helmet/Helmet";
import Slider from "@mui/material/Slider";


const Inventory = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    if (searchKeyword.trim()) {
      navigate(`/inventory/${searchKeyword}`);
    } else {
      navigate("/inventory");
    }
  };


  const bodyTypes = [
    "Sedan",
    "Coupe",
    "Minivan",
    "Wagon",
    "Sports",
    "Hatchback",
    "CUV",
    "SUV",
    "MUV",
    "Pickup Trucks",
    "Convertible",
  ]

  const [currentPage, setcurrentPage] = useState(1);

  const { loading, error, cars, carsCount, resultPerPage, filteredCarsCount } =
    useSelector((state) => state.cars);
    

  const { keyword } = useParams();

  const setcurrentPageNo = (e) => {
    setcurrentPage(e);
  };
  const [price, setprice] = useState([10000, 800000]);
  const [bodyType, setBodyType] = useState("");

  const priceHandler = (event, newPrice) => {
    setprice(newPrice);
  };


  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCar(keyword, currentPage, price, bodyType));
  }, [dispatch, keyword, currentPage, price, bodyType , alert, error]);

  let count = filteredCarsCount;


  const customSort = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA < dateB) return 1;
    else if (dateA > dateB) return -1;
    return 0;
  };

  cars.sort(customSort)

  return (
    <Helmet title="Inventory">
      <Fragment>
        {/* <--- Breadcrumb ---> */}

        <nav
          aria-label="breadcrumb"
          className="w-full p-4 bg-[#071738] text-gray-100"
        >
          <ol className="flex h-8 space-x-2">
            <li className="flex items-center">
            <Link
               to={'/home'}
                title="Back to homepage"
                className="hover:underline"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 pr-1 text-gray-400"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
              </Link>
            </li>
            <li className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                aria-hidden="true"
                fill="currentColor"
                className="w-2 h-2 mt-1 transform rotate-90 fill-current text-gray-600"
              >
                <path d="M32 30.031h-32l16-28.061z"></path>
              </svg>
              <Link
               to={'/inventory'}
                className="flex items-center px-1 capitalize hover:underline"
              >
                Inventory
              </Link>
            </li>
          </ol>
        </nav>

        {/* <--- Breadcrumb ---> */}
       
        
          <div className="grid md:grid-cols-9  sticky grid-flow-row auto-rows-max">
            <div className=" md:col-span-2 md:mt-4  grid order-last md:order-none">
              {/*col1*/}
              {/* <--- Sidebar ---> */}
             
              <aside className="w-full md:h-screen p-6  md:rounded-xl mx-4 sm:w-11/12 justify-self-center bg-[#071738] text-gray-100">
                <nav className="space-y-8 text-sm">
                  <div className="space-y-2">
                    <div className="relative">
                      <form className="search__box" onSubmit={submitHandler}>
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                          <button
                            type="submit"
                            title="Search"
                            className="p-1 focus:outline-none focus:ring"
                          >
                            <svg
                              fill="currentColor"
                              viewBox="0 0 512 512"
                              className="w-4 h-4 text-gray-100"
                            >
                              <path d="M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z"></path>
                            </svg>
                          </button>
                        </span>
                        <input
                          type="text"
                          name="Search"
                          placeholder="Search..."
                          value={searchKeyword}
                          onChange={(e) => setSearchKeyword(e.target.value)}
                          className="w-full py-2 pl-10 text-sm rounded-md sm:w-full focus:outline-none bg-[#142956] text-gray-100 focus:bg-[#04112D]"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="space-y-2">
                  <h2 className="text-sm font-semibold tracking-widest uppercase text-gray-400">
                      Budget
                    </h2>
                    <div className="relative">
                      <Slider
                        value={price}
                        onChange={priceHandler}
                        valueLabelDisplay="off"
                        aria-labelledby="range-slider"
                        min={10000}
                        max={80000}
                        color={'primary'}
                      />

                      <div className="w-full flex justify-between text-xs px-">
                        <span className="text-sm">$ {price[0]}</span>
                        {/* <span>|</span>
                        <span>|</span>
                        <span>|</span> */}
                        <span className="text-sm">$ {price[1]}</span>
                      </div>
                    </div>
                  
                  </div>
                  <div className="space-y-2">
                    <h2 className="text-sm font-semibold tracking-widest uppercase text-gray-400">
                      Body Types
                    </h2>
                    <div className="flex flex-col space-y-1">
                    <ul className="filterBox ml-4">
                    <li
                  className="text-gray-300 leading-8 hover:text-[#E75F22] cursor-pointer"
                  onClick={() => setBodyType("")}
                >
                  All
                </li>
              {bodyTypes.map((bodyType) => (
                  <li
                  className="text-gray-300 leading-8 hover:text-[#E75F22] cursor-pointer"
                  key={bodyType}
                  onClick={() => setBodyType(bodyType)}
                >
                  {bodyType}
                </li>
              ))}
              </ul>
                    </div>
                  </div>
                </nav>
              </aside>
              {/* <--- Sidebar ---> */}
            </div>
           
            <div className=" md:col-span-7 ">
              {/*col2*/}
              {loading ? (
          <Loader />
        ) : (
              <div className="max-w-[1240px] md:mt-1 pt-3 px-4 h-screen overflow-y-auto scrollbar-hide mx-auto grid md:grid-cols-3 gap-8">
                 {cars.length === 0 ? (
                  <div className="flex items-center flex-col col-span-full  justify-center">
                  <i class="ri-error-warning-line text-8xl text-slate-400"></i>
      <p className="text-4xl text-[#224185] font-semibold">No cars to display!</p></div>
    ) : ( cars.map((car) => <Car key={car._id} car={car} />))}
              </div>
          )}

            </div>
          
          
          
          {resultPerPage < count && (
          <div className="flex md:col-span-full justify-center mt-4 p-4 space-x-1 bg-[#071738]">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={carsCount}
              onChange={setcurrentPageNo}
              itemClass="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow-md bg-[#071738] border-gray-800 text-white text-sm"
              linkClass="page-link"
              activeClass="inline-flex items-center justify-center w-8 h-8 text-sm text-white font-semibold border rounded shadow-md bg-[#071738] text-white border-white"
              activeLinkClass=""
              className="inline-flex items-center justify-center w-8 h-8 py-0 border rounded-md shadow-md bg-[#071738] border-gray-800"
            />
          </div>
        )}
          </div>
        {/* <--- Pagination ---> */}

        

        {/* <--- Pagination ---> */}
      </Fragment>
    </Helmet>
  );
};

export default Inventory;
