import React from 'react'

const Loader = () => {
  return (
    <div className="flex items-center justify-center space-x-2 h-[100vh]">
	<div className="w-4 h-4 rounded-full animate-pulse bg-[#2750A4]"></div>
	<div className="w-4 h-4 rounded-full animate-pulse bg-[#2750A4]"></div>
	<div className="w-4 h-4 rounded-full animate-pulse bg-[#2750A4]"></div>
</div>
  )
}

export default Loader