import React from 'react'
import { Fragment } from 'react'
import Sidebar from './Sidebar'
import help from './developer_male.png'
import Helmet from '../Components/Helmet/Helmet';

const Developer = () => {
  return (
    <Helmet title={'Get Help'}>

    <Fragment>
     


     <div className="grid h-screen bg-slate-800 ">
  <div className=" overflow-hidden">
  
    <div className="grid grid-cols-5 h-screen">
      <nav className="grid col-span-1 h-full">
      <Sidebar />
        
      </nav>
      <main className="grid col-span-4 h-screen  bg-[#071738] overflow-x-hidden overflow-y-auto mb-14">
      <div className="bg-[#010d23] py-4 self-center fixed top-0 w-full">

<h2 className="text-2xl px-8 text-gray-100 font-semibold leading-tight">Contact Developer</h2>
      </div>
        
      <div className="container h-screen grid self-center p-2 mx-auto sm:p-4 text-gray-100">
      
      <div className="grid grid-cols-2  rounded-xl self-center bg-[#010d23] max-w-xl mx-auto px-10 py-12">
        <div className="col-span-1 mx-auto self-center">
<img src={help} alt="" />
</div>
<div className="col-span-1 self-center text-right">
  <h4 className='text-xl font-medium pb-2'>Technical Support</h4>
  <p>Email: <a className='text-[#467bed]' href="mailto:truth2it@truth2it.com">truth2it@truth2it.com</a> </p>
</div>
      </div>

     <h2 className='opacity-5 text-gray-400'>©Ronit Roy</h2> 
 </div>
      </main>
     
        
      </div>
    </div>
  </div>

    </Fragment>
    </Helmet>
  )
}

export default Developer