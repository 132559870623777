import React, { useState } from "react";

function PriceRangeFilter() {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);

  const handleMinPriceChange = (event) => {
    setMinPrice(event.target.value);
  };

  const handleMaxPriceChange = (event) => {
    setMaxPrice(event.target.value);
  };

  return (
    <div className="flex flex-col">
      <label className="text-sm font-medium">
        Min Price:
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full leading-5"
          type="number"
          value={minPrice}
          onChange={handleMinPriceChange}
        />
      </label>
      <label className="text-sm font-medium mt-2">
        Max Price:
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full leading-5"
          type="number"
          value={maxPrice}
          onChange={handleMaxPriceChange}
        />
      </label>
    </div>
  );
}

export default PriceRangeFilter;
