import "remixicon/fonts/remixicon.css";
import React, {useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { clearErrors, getCar } from "../../actions/carAction";




const CarSearch = () => {
  const { error } =
  useSelector((state) => state.cars);

  const [searchKeyword, setSearchKeyword] = useState("");
  const { keyword } = useParams();
let navigate = useNavigate();
const alert = useAlert();
  const dispatch = useDispatch();




  const submitCarHandler = (e) => {
    e.preventDefault();
    if (searchKeyword.trim()) {
      navigate(`/inventory/${searchKeyword}`);
    }     
    else {
      alert.info("Please enter keyword");
    }
  };


  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCar(keyword));
  }, [dispatch, keyword,alert, error]);



  return (
    <form onSubmit={submitCarHandler} className="block">
      <div className=" flex flex-wrap border-2 items-center w-5/6 md:w-4/6 bg-[#071738] item-center ml-auto mr-auto justify-center border-slate-900 rounded-lg py-4 mb-12    ">
        <div className=" align-middle w-[80%] md:w-1/2 my-4 md:my-0 ">
          <div className=" flex">
            <span className="pl-4 text-lg text-white mt-[0.15rem]">
              <i className="ri-search-2-line"></i>
            </span>
            <input
              className="pl-2 text-white text-md bg-transparent outline-none"
                          name="Search"
                          placeholder="eg: BMW 3 Series..."
                          value={searchKeyword}
                          onChange={(e) => setSearchKeyword(e.target.value)}
            ></input>
          </div>
          {/* <br className='black py-2' /> */}
        </div>
        
        <div className=" align-middle my-4 md:my-0">
          <div className=" flex">
            <button type="submit" className="px-24 md:px-16 rounded-md py-2 mx-4 sm:my-4 item-center bg-[#224185] text-white hover:scale-105 hover:bg-[#254DAA]"> Search</button>
          </div>
          {/* <br className='black py-2' /> */}
        </div>
       
        
        
      </div>
    </form>
  );
};

export default CarSearch;
