import './App.css';

import React  from 'react'
// import store from './store'
// import { loadUser } from "./actions/userAction";
import Layout from './Components/Layout/Layout';




function App() {


  // useEffect(() => {
  //   store.dispatch(loadUser())
  // })
  
  return <Layout />
}

export default App;
