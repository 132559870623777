import {LOGIN_REQUEST,LOGIN_FAIL,LOGIN_SUCCESS,CLEAR_ERRORS,LOAD_USER_FAIL,LOAD_USER_REQUEST,LOAD_USER_SUCCESS,LOGOUT_SUCCESS,LOGOUT_FAIL} from "../constants/userConstants";
import axios from "axios";



//Login
export const login = (username,password) => async (dispatch) => {
    try {
    dispatch({type: LOGIN_REQUEST});
    
        const config = {headers: {"Content-Type": "application/json"}};

    const {data} = await axios.post(
        `/api/v1/login`,
        {username,password},
        config
    );
    dispatch({type: LOGIN_SUCCESS, payload:data.user});
    } catch (error) {
        dispatch({type:LOGIN_FAIL,payload: error.response.data.message});
    };
};


//Load User
export const loadUser = () => async (dispatch) => {
    try {
    dispatch({type: LOAD_USER_REQUEST});
    

    const {data} = await axios.get(
        `/api/v1/me`,
    );
    dispatch({type: LOAD_USER_SUCCESS, payload:data.user});
    } catch (error) {
        dispatch({type:LOAD_USER_FAIL,payload: error.response.data.message});
    };
};



//Logout User
export const logout = () => async (dispatch) => {
    try {    

        await axios.get(
        `/api/v1/logout`,
    );
    dispatch({type: LOGOUT_SUCCESS,});
    } catch (error) {
        dispatch({type:LOGOUT_FAIL,payload: error.response.data.message});
    };
};


//Clearing Errors
export const clearErrors = ()=> async (dispatch)=>{
    dispatch({ type: CLEAR_ERRORS });
};


