import React from 'react'
import { Link } from 'react-router-dom'

const Cta = () => {
  return (
    <div className="">
        <div className="w-full  bg-black " >
	<div className="container  p-4 py-24 mx-auto md:py-24">
  <div className="flex items-center flex-wrap gap-12 lg:gap-0">
                    <div className="lg:w-5/12 space-y-8">
                    <h1 className="text-3xl py-4 antialiased font-semibold leading-none text-[#315FCA]">Explore our vast selection of preowned vehicles!</h1>
		<p className="pt-2 pb-8 text-xl antialiased text-gray-100">Browse our inventory now to find your dream car.</p>
		<Link to="/inventory"><button className="px-8 py-3 w-56 text-lg font-semibold rounded bg-[#224185] text-gray-50 hover:bg-gray-50 hover:text-[#224185]">View Inventory</button></Link>
                        </div>
                    <div className=" relative md:block lg:w-7/12">
                        <img src="https://images.pexels.com/photos/261986/pexels-photo-261986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" className="relative ml-auto mb-[-5rem]" alt=""/>
                    </div>
                    </div>

                </div>
      
           
        </div>
		
	</div>

  )
}

export default Cta