import axios from "axios";


import {
    CAREER_FAIL,
    CAREER_REQUEST,
    CAREER_SUCCESS,
    DELETE_CAREER_FAIL,
    DELETE_CAREER_REQUEST,
    DELETE_CAREER_SUCCESS,
    ALL_CAREER_FAIL,
    ALL_CAREER_REQUEST,
    ALL_CAREER_SUCCESS,
    CLEAR_ERRORS,
} from "../constants/careerConstants";


//Create Career
export const newCareer = (careerData) => async (dispatch) => {
    try {
      dispatch({ type:CAREER_REQUEST });
  
      const config = {
        headers: {"Content-Type": "multipart/form-data"},
      };
      const { data } = await axios.post(`/api/v1/career/new`,careerData,config);
  
      dispatch({
        type: CAREER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CAREER_FAIL,
        payload: error.response.data.message,
      });
    }
  };


  // Get ALL Careers in ADMIN
  export const getAllCareers = () => async (dispatch) => {
    try {
      dispatch({ type:ALL_CAREER_REQUEST });
  
      const { data } = await axios.get(`/api/v1/admin/careers`);
  
      dispatch({
        type: ALL_CAREER_SUCCESS,
        payload: data.careers,
      });
    } catch (error) {
      dispatch({
        type: ALL_CAREER_FAIL,
        payload: error.response.data.message,
      });
    }
  };


//Delete Career --- Admin
export const deleteCareer = (id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_CAREER_REQUEST });
  
      const { data } = await axios.delete(`/api/v1/admin/career/${id}`);
  
      dispatch({
        type: DELETE_CAREER_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: DELETE_CAREER_FAIL,
        payload: error.response.data.message,
      });
    }
  };


 


  //Clearing Errors
export const clearErrors = ()=> async (dispatch)=>{
    dispatch({ type: CLEAR_ERRORS });
};