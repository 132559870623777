import React from 'react'

const Map = () => {
  return (
    <div className="">
        <h3 className='text-center py-10 text-2xl text-[#224185] font-bold'>Driving directions</h3>
        <iframe title='Driving Directions' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2997.8990898724537!2d-73.07903248480847!3d41.289300610033386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e87557ee65c8cd%3A0xab77c34e9a42c67!2s468%20River%20Rd%2C%20Shelton%2C%20CT%2006484%2C%20USA!5e0!3m2!1sen!2sin!4v1675768947415!5m2!1sen!2sin" height="450" className='w-full ' ></iframe>
    </div>
  )
}

export default Map