import { Fragment, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { clearErrors,getAllCareers,deleteCareer } from "../actions/careerAction";
import { useAlert } from "react-alert";
import { DELETE_CAREER_RESET } from '../constants/careerConstants';
import Helmet from '../Components/Helmet/Helmet';
import Sidebar from "./Sidebar";



const AllCareer = () => {
    const dispatch = useDispatch();
    const alert = useAlert();

    const {  error, careers } = useSelector((state) => state.allCareers);
    const { error: deleteError, isDeleted} = useSelector((state) => state.allCareers)


    const deleteCareerHandler = (id) => {
        dispatch(deleteCareer(id));
    };

 const customSort = (a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateA < dateB) return 1;
        else if (dateA > dateB) return -1;
        return 0;
      };

      careers.sort(customSort)



    useEffect(() => {
        if (error) {
          alert.error(error);
          dispatch(clearErrors());
        }

        if (deleteError) {
            alert.error(deleteError);
            dispatch(clearErrors());
        }
        if (isDeleted) {
            alert.success("Career Deleted succesfully");
            dispatch({ type: DELETE_CAREER_RESET });
        }
        dispatch(getAllCareers(),);
      }, [dispatch, alert, error,deleteError,isDeleted]);

      
      const downloadCSV = () => {


        const header = ['S. No.', 'Name', 'Email', 'Message', 'Date'];
const rows = careers.map((e, index) => [`${index+1}`,e.name, e.email, e.createdAt.slice(0,10)]);
const csv = [header, ...rows].map(r => r.join(',')).join('\n');
const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'All-Careers.csv';
        link.click();
};
    
const handleDownload = async (career) => {
    const response = await fetch(`http://localhost:3000/${career.pdf}`);
  const blob = await response.blob();
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${career.name}.pdf`;
  link.click();
  };


return (
<Helmet title={'All Careers'}>
<Fragment >
 


 <div className="grid h-screen bg-slate-800 ">
<div className=" overflow-hidden">

<div className="grid grid-cols-5 h-screen">
  <nav className="grid col-span-1 h-full">
  <Sidebar />
    
  </nav>
  <main className="col-span-4 h-screen  bg-[#071738] overflow-x-hidden overflow-y-auto mb-14">
  <div className="bg-[#010d23] container grid  mx-auto    ">
<div className=" grid py-4 grid-cols-2">
<h2 className="text-2xl px-8 text-gray-100 self-center font-semibold leading-tight">All Careers</h2>
<span className='self-center justify-self-end pr-12 text-sm text-white '><button onClick={downloadCSV} className='bg-[#2f5bb5] hover:bg-[#0e1e3e] flex self-center gap-1 p-2 rounded-md'>Export CSV <i className="ri-file-download-line text-sm"></i></button> </span>
</div>
  </div>
  <div className="container mx-auto text-gray-100">
    
 <div className="overflow-x-auto">
     <table className="min-w-full text-xs">
     <thead className="bg-[#2f5bb5]">
             <tr className="text-left">
                 <th className="p-3 py-4">Name</th>
                 <th className="p-3">Email</th>
                 <th className="p-3">Message</th>
                 <th className="p-3">Resume</th>
                 <th className="p-3">Date</th>
                 <th className="p-3 text-right">Action</th>
             </tr>
         </thead>
         {careers.map((career) => (
      <Fragment key={career._id}>
      <tbody>
                  <tr className="border-b border-opacity-20 border-gray-700 bg-[#071738] hover:bg-[#46609c56]">
                      <td className="p-3">
                          <p>{career.name}</p>
                      </td>
                      <td className="p-3">
                          <p>{career.email}</p>
                      </td>
                      <td className="p-3 w-[40%]">
                          <p>{career.message}</p>
                      </td>
                      <td className="p-3">
      
                          <button className="px-4 py-2 flex gap-2 self-center font-medium rounded-sm hover:bg-green-700 bg-gray-500" onClick={() => handleDownload(career)}>Download <i className="ri-download-2-fill"></i></button>
                      </td>
            <td className="p-3">
                          <p>{career.createdAt.slice(0,10)}</p>
                      </td>
                      
                      <td className="p-3 text-right text-xl cursor-pointer">
                          
                          <button title='Delete' onClick={() => deleteCareerHandler(career._id)} className='ml-4'>
                          <i className="ri-delete-bin-2-line text-red-400 px-2 hover:text-red-600"></i>
                          </button>
                          
                      </td>
                  </tr>
                  
              </tbody>
              </Fragment>
    ))}
        
        
     </table>
 </div>
</div>
  </main>
  
    
  </div>
</div>
</div>

</Fragment>
</Helmet>
)
}

export default AllCareer