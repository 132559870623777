import React, { Fragment, useState } from 'react'
import Sidebar from './Sidebar'
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { getAdminCar, } from "../actions/carAction";
import { getAllEnquiries } from "../actions/enquiryAction";
import { getAllContacts } from "../actions/contactAction";
import { getAllCareers } from "../actions/careerAction";
import { Link } from 'react-router-dom';
import CarIcon from './car.png';
import EnquiryIcon from './enquiry.svg';
import CareerIcon from './career.svg';
import PhoneScreen from './PhoneScreen';
import Helmet from '../Components/Helmet/Helmet';


const Dashboard = () => {

    const dispatch = useDispatch();

        const {cars} = useSelector((state) => state.cars)
        const {enquiries } = useSelector((state) => state.allEnquiries);
        const {contacts } = useSelector((state) => state.allContacts);
        const {careers } = useSelector((state) => state.allCareers);




    
        useEffect(() => {
            dispatch(getAdminCar());
            dispatch(getAllEnquiries());
            dispatch(getAllContacts());
            dispatch(getAllCareers());
        }, [dispatch,]);

        const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Helmet title={'Dashboard'}>
    <Fragment >
     {width < 1030 ? <PhoneScreen/> :


      <div className="grid h-screen bg-slate-800 ">
  <div className=" overflow-hidden">
  
    <div className="grid grid-cols-5 h-screen">
      <nav className="grid col-span-1 h-full">
      <Sidebar />
        
      </nav>
      <main className="grid col-span-4 h-screen  bg-[#071738] overflow-x-hidden overflow-y-auto mb-14">
        <div className="bg-[#010d23] z-10 py-4 self-center fixed top-0 w-full">

	<h2 className="text-2xl px-8 text-gray-100 font-semibold leading-tight">Dashboard</h2>
        </div>
      <div className="container mt-8 p-2 mx-auto sm:p-4 text-gray-100">
 	
  
  <div className=" py-16">
  <div className="container grid  m-auto px-6 text-gray-500 md:px-12">
    <div className="grid gap-6 self-center md:mx-auto md:w-8/12 lg:w-full lg:grid-cols-3  ">
      <div className="group space-y-6 border col-span-3  border-gray-700 rounded-3xl  bg-[#010d23] px-8 py-12 text-center  shadow-gray-600/10 shadow-none">
        <img
          className="mx-auto h-14 my-2  hover:scale-105 duration-300"
          alt='Car Icon'
          src={CarIcon}
        />
        
        <h2 className='text-6xl text-[#467bed]'>
          {cars?.length}
        </h2>
        <h3 className="text-xl font-semibold  text-white">Cars in Inventory</h3>
        <Link to={'/dashboard/inventory'}
          className="relative mx-auto flex h-10 w-10 items-center justify-center before:absolute before:inset-0 before:rounded-full before:border  before:border-gray-600 before:transition before:duration-300 group-hover:before:scale-125"
        >
          <span className="text-[#467bed]"><i className="ri-arrow-right-line"></i></span>
        </Link>
      </div>
      <div className="group space-y-6 border  border-gray-700 rounded-3xl  bg-[#010d23] px-8 py-12 text-center shadow-gray-600/10 shadow-none">
       
      <img
          className="mx-auto h-14 my-2  hover:scale-105 duration-300"
          alt='Enquiry Icon'
          src={EnquiryIcon}
        />

        <h2 className='text-6xl text-[#467bed]'>
          {enquiries?.length}
        </h2>
        <h3 className="text-xl font-semibold  text-white">Enquiries</h3>
        <Link to={'/dashboard/enquiry'}
          className="relative mx-auto flex h-10 w-10 items-center justify-center before:absolute before:inset-0 before:rounded-full before:border  before:border-gray-600 before:transition before:duration-300 group-hover:before:scale-125"
        >
          <span className="text-[#467bed]"><i className="ri-arrow-right-line"></i></span>
        </Link>
       
      </div>
      <div className="group space-y-6 border  border-gray-700 rounded-3xl  bg-[#010d23] px-8 py-12 text-center  shadow-gray-600/10 shadow-none">
       
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="mx-auto h-16 hover:scale-105 duration-300">
  <path fillRule="evenodd" d="M12 2.25c-2.429 0-4.817.178-7.152.521C2.87 3.061 1.5 4.795 1.5 6.741v6.018c0 1.946 1.37 3.68 3.348 3.97.877.129 1.761.234 2.652.316V21a.75.75 0 001.28.53l4.184-4.183a.39.39 0 01.266-.112c2.006-.05 3.982-.22 5.922-.506 1.978-.29 3.348-2.023 3.348-3.97V6.741c0-1.947-1.37-3.68-3.348-3.97A49.145 49.145 0 0012 2.25zM8.25 8.625a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zm2.625 1.125a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z" clipRule="evenodd" />
</svg>

        <h2 className='text-6xl text-[#467bed]'>
          {contacts?.length}
        </h2>
        <h3 className="text-xl font-semibold  text-white">Contact Requests</h3>
        <Link to={'/dashboard/contact-form'}
          className="relative mx-auto flex h-10 w-10 items-center justify-center before:absolute before:inset-0 before:rounded-full before:border  before:border-gray-600 before:transition before:duration-300 group-hover:before:scale-125"
        >
          <span className="text-[#467bed]"><i className="ri-arrow-right-line"></i></span>
        </Link>
       
      </div>
      <div className="group space-y-6 border  border-gray-700 rounded-3xl  bg-[#010d23] px-8 py-12 text-center  shadow-gray-600/10 shadow-none">
       
      <img
          className="mx-auto h-14 my-2  hover:scale-105 duration-300"
          alt='Career Icon'
          src={CareerIcon}
        />

        <h2 className='text-6xl text-[#467bed]'>
          {careers?.length}
        </h2>
        <h3 className="text-xl font-semibold  text-white">Job Requests</h3>
        <Link to={'/dashboard/all-careers'}
          className="relative mx-auto flex h-10 w-10 items-center justify-center before:absolute before:inset-0 before:rounded-full before:border  before:border-gray-600 before:transition before:duration-300 group-hover:before:scale-125"
        >
          <span className="text-[#467bed]"><i className="ri-arrow-right-line"></i></span>
        </Link>
       
      </div>
    </div>
  </div>
</div>
                                    



 </div>
      </main>
      
        
      </div>
    </div>
  </div>
}
    </Fragment>
    </Helmet>
  )
}

export default Dashboard