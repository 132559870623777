import React, { useEffect, useState } from "react";
import Helmet from "../Components/Helmet/Helmet";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

import { clearErrors, newCareer } from "../actions/careerAction";
import { CAREER_RESET } from "../constants/careerConstants";
import { useDispatch } from "react-redux";


const Careers = () => {
  const { error, success } = useSelector((state) => state.newCareer);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Request send successfully");
      dispatch({ type: CAREER_RESET });
    }
  }, [dispatch, alert, error, success]);

  const careerSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("message", message);
    myForm.set("pdf", file);

    dispatch(newCareer(myForm), file);
  };

  return (
    <Helmet title="Careers">
      <div className="grid container my-14 grid-cols-1 gap-8 px-8 py-16 mx-auto rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 bg-[#071738] text-gray-100">
        <div className="flex flex-col justify-between">
          <div className="space-y-2">
            <h2 className="text-4xl font-bold leading-tight lg:text-5xl">
              Join Us!
            </h2>
            <div className="text-gray-400">Become a part of our team.</div>
          </div>
          <img
            src="https://svgshare.com/i/pnk.svg"
            alt=""
            className=" h-52 self-start md:h-64"
          />
        </div>
        <form
          noValidate=""
          onSubmit={careerSubmitHandler}
          className="space-y-6 ng-untouched ng-pristine ng-valid"
        >
          <div>
            <label htmlFor="name" className="text-sm">
              Full name
            </label>
            <input
              id="name"
              type="text"
              value={name}
              className="w-full p-3 border border-gray-400 rounded bg-[#071738]"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="email" className="text-sm">
              Email
            </label>
            <input
              id="email"
              value={email}
              type="email"
              className="w-full p-3 rounded border border-gray-400 bg-[#071738]"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="message" className="text-sm">
              Message
            </label>
            <textarea
              id="message"
              rows="3"
              className="w-full p-3 rounded border border-gray-400 bg-[#071738]"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="mx-auto">
            <fieldset className="w-full  text-gray-100">
              <label htmlFor="files" className="block text-sm font-medium">
                Attachments
              </label>
              <div className="flex">
                <input
                  type="file"
                  name="file"
                  id="files"
                  className="px-8 py-12 w-full border-2 border-dashed rounded-md border-gray-400 text-gray-400"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
            </fieldset>
          </div>
          <button
            type="submit"
            className="w-full p-3 text-sm font-bold tracking-wide uppercase rounded bg-[#315FCA] text-white hover:text-[#315FCA] hover:bg-white"
          >
            Send Message
          </button>
        </form>
      </div>
    </Helmet>
  );
};

export default Careers;
