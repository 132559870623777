import React from "react";


const Header = () => {
  return (
    <div className="bg-[#071738] shadow-md hidden md:flex text-white p-2 text-sm ">
        <div className="px-6 md:px-12 lg:container lg:mx-auto lg:px-6 flex items-center justify-between ">
            <div className="flex items-center">

      Need Help?
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-4 h-4 mx-2"
        >
          <path
            fillRule="evenodd"
            d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      <span>203-903-1544</span>
            </div>
        <div className="flex">
          Follow us on
            <span className="mx-2 flex items-center text-[1.1rem]"><i className="ri-facebook-fill"></i></span>
            <span className="mx-2 flex items-center text-[1.1rem]"><i className="ri-instagram-fill"></i></span>
            <span className="mx-2 flex items-center text-[1.1rem]"><i className="ri-youtube-fill"></i></span>
        </div>
      </div>
    </div>
  );
};

export default Header;
