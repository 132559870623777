export const ENQUIRY_REQUEST="ENQUIRY_REQUEST";
export const ENQUIRY_SUCCESS="ENQUIRY_SUCCESS";
export const ENQUIRY_RESET="ENQUIRY_RESET";
export const ENQUIRY_FAIL="ENQUIRY_FAIL";

export const ALL_ENQUIRY_REQUEST="ALL_ENQUIRY_REQUEST";
export const ALL_ENQUIRY_SUCCESS="ALL_ENQUIRY_SUCCESS";
export const ALL_ENQUIRY_FAIL="ALL_ENQUIRY_FAIL";

export const DELETE_ENQUIRY_REQUEST="DELETE_ENQUIRY_REQUEST";
export const DELETE_ENQUIRY_SUCCESS="DELETE_ENQUIRY_SUCCESS";
export const DELETE_ENQUIRY_RESET="DELETE_ENQUIRY_RESET";
export const DELETE_ENQUIRY_FAIL="DELETE_ENQUIRY_FAIL";

export const CLEAR_ERRORS="CLEAR_ERRORS";   
